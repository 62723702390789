import React from 'react';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import AgreedRate from '../../../../models/AgreedRate';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';

const AgreedRates = ({ companyId }) => {
  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/agreed-rate/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/agreed-rate/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const columns = [
    { label: 'ID', field: 'id' },
    { label: 'Course', field: 'course.name' },
    { label: 'Delivery', field: 'delivery.type' },
    { label: 'Trainer', field: 'trainer.name' },
    {
      label: 'Price',
      field: 'price'
    },
    { label: 'Status', field: 'status.type' }
  ];

  const filters = [
    { field: 'id', label: 'ID', type: 'number' },
    {
      field: 'course.name',
      label: 'Course',
      type: 'text',
      operator: FilterOperatorEnum.ILIKE
    },
    {
      field: 'delivery.type',
      label: 'Delivery',
      type: 'text',
      operator: FilterOperatorEnum.ILIKE
    },
    {
      field: 'trainer.name',
      label: 'Trainer',
      type: 'text',
      operator: FilterOperatorEnum.ILIKE
    },
    {
      field: 'price',
      label: 'Price',
      type: 'number',
      operator: FilterOperatorEnum.GREATER_THAN_OR_EQUAL
    },
    {
      field: 'status.type',
      label: 'Status',
      type: 'text',
      operator: FilterOperatorEnum.EQUALS
    }
  ];

  return (
    <EntityTable
      name='Agreed Rates'
      filter={filters}
      columns={columns}
      menuItems={menuItems}
      model={AgreedRate}
      entityType={EntityTypeEnum.AgreedRates}
      defaultFilters={{
        company_id: { value: companyId }
      }}
    />
  );
};

export default AgreedRates;
