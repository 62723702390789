/**
 * Enumeration of entity types used throughout the application.
 * Each entity type is assigned a unique numeric identifier.
 *
 **/
export const EntityTypeEnum = {
  AccreditationBody: 1,
  Booking: 2,
  BookingSpace: 3,
  Company: 4,
  Course: 5,
  CourseDelivery: 6,
  CourseOption: 7,
  Industry: 8,
  CourseStatus: 9,
  CompanyStatus: 10,
  CompanyTerms: 11,
  Document: 12,
  DocumentStatus: 13,
  Compliance: 14,
  ComplianceType: 15,
  ComplianceStatus: 16,
  Trainer: 17,
  TrainerStatus: 18,
  Location: 19,
  LocationType: 20,
  LocationStatus: 21,
  ActivityLog: 22,
  ActivityType: 23,
  Filter: 24,
  User: 25,
  AdditionalCharge: 26,
  AdditionalChargeStatus: 27,
  AdditionalChargeType: 28,
  BookingStatus: 29,
  BookingSubStatus: 30,
  BookingSpaceLevyType: 31,
  Task: 32,
  TaskCategory: 33,
  TaskStatus: 34,
  Meeting: 35,
  MeetingCategory: 36,
  MeetingStatus: 37,
  HigherRate: 38,
  AgreedRateStatus: 39,
  Quote: 40,
  QuoteStatus: 41,
  QuoteOption: 42,
  QuoteOptionStatus: 43,
  CourseOptionStatus: 44,
  ContactTypes: 45,
  BookingSpaceStatus: 46,
  AgreedRates: 47,
  Pipelines: 48
};

/**
 * Enum for filter operators used in query building.
 * These operators define how filter conditions are applied to fields.
 * @enum {string}
 */
export const FilterOperatorEnum = {
  /** Equality comparison */
  EQUALS: 'eq',
  /** Not equal comparison */
  NOT_EQUALS: 'neq',
  /** Greater than comparison */
  GREATER_THAN: 'gt',
  /** Greater than or equal to comparison */
  GREATER_THAN_OR_EQUAL: 'gte',
  /** Less than comparison */
  LESS_THAN: 'lt',
  /** Less than or equal to comparison */
  LESS_THAN_OR_EQUAL: 'lte',
  /** Case-insensitive pattern matching */
  ILIKE: 'ilike',
  /** Exact pattern matching */
  LIKE: 'like',
  /** Check if value is in a list */
  IN: 'in',
  /** Check if value is not in a list */
  NOT_IN: 'not.in',
  /** Check if value is null */
  IS_NULL: 'is.null',
  /** Check if value is not null */
  IS_NOT_NULL: 'not.is.null',
  /**Text search */
  TEXTSEARCH: 'textSearch'
};
