import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import EntityCard from './EntityCard';
import TabView from '../navigation/TabView';
import Feed from '../feeds/Feed';
import ActivityLog from '../../models/ActivityLog';
import Meeting from '../../models/Meeting';
import { formatDate } from '../../utilities/Formatting';
import { EntityTypeEnum } from '../../utilities/Enumerables';
import TaskOverview from './overviews/TaskOverview';
import MeetingOverview from './overviews/MeetingOverview';
import Pagination from '../navigation/Pagination';
import { Alert, LogoLoader, ProgressBar } from 'core';
import FileOverview from './overviews/FileOverview';
import ManagePipelines from '../popups/pipelines/ManagePipelines';

const Entity = ({
  currentUser,
  entityType,
  model,
  infoItems,
  accordionItems,
  additionalTabs = [],
  progressItems
}) => {
  const { id } = useParams();
  const [entity, setEntity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [feedItems, setFeedItems] = useState([]);
  const [noteFeedItems, setNoteFeedItems] = useState([]);
  const [currentFeedPage, setCurrentFeedPage] = useState(1);
  const [currentNoteFeedPage, setCurrentNoteFeedPage] = useState(1);
  const [pinnedFeedItem, setPinnedFeedItem] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [totalFeedPages, setTotalFeedPages] = useState(1);
  const [totalNoteFeedPages, setTotalNoteFeedPages] = useState(1);
  const [showPipelinesPopup, setShowPipelinesPopup] = useState(false);
  const itemsPerFeedPage = 10;

  const logTypes = {
    115: { title: 'Info' },
    116: { title: 'Warning' },
    117: { title: 'Danger' },
    118: { title: 'Changed' },
    119: { title: 'Added a Note to' },
    120: { title: 'Email Added' },
    121: { title: 'SMS Added' },
    122: { title: 'Call Added' }
  };

  const fetchEntityData = useCallback(async () => {
    try {
      setLoading(true);
      const entityData = await model.getById(id);
      setEntity(entityData);

      const [
        { data: activityLogs, count: feedcount },
        { data: noteActivityLogs, count: notecount },
        { data: pinnedItems },
        { data: meetingsData }
      ] = await Promise.all([
        ActivityLog.getAll(
          { entity_type: { value: entityType }, entity_id: { value: id } },
          currentFeedPage,
          itemsPerFeedPage,
          'created_date',
          'desc'
        ),
        ActivityLog.getAll(
          {
            entity_type: { value: entityType },
            entity_id: { value: id },
            audit_type: { value: 119 }
          },
          currentNoteFeedPage,
          itemsPerFeedPage,
          'created_date',
          'desc'
        ),
        ActivityLog.getAll(
          {
            entity_type: { value: entityType },
            entity_id: { value: id },
            pinned: { value: true }
          },
          1,
          1,
          'created_date',
          'desc'
        ),
        Meeting.getAll(
          {
            entity_type: { value: EntityTypeEnum[entityType] },
            entity_id: { value: id }
          },
          1,
          10,
          'start_date',
          'asc'
        )
      ]);

      setFeedItems(activityLogs);
      setTotalFeedPages(Math.ceil(feedcount / itemsPerFeedPage));
      setNoteFeedItems(noteActivityLogs);
      setTotalNoteFeedPages(Math.ceil(notecount / itemsPerFeedPage));
      setPinnedFeedItem(pinnedItems[0] || null);
      setMeetings(meetingsData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [id, model, entityType, currentFeedPage, currentNoteFeedPage]);

  useEffect(() => {
    fetchEntityData();
  }, [fetchEntityData]);

  const handleUpdate = useCallback(
    async (field, value) => {
      try {
        let updateData;
        if (field.includes('.')) {
          updateData = field
            .split('.')
            .reduceRight((acc, key) => ({ [key]: acc }), value);
        } else {
          updateData = { [field]: value };
        }
        const updatedEntity = await entity.update(updateData);
        setEntity(updatedEntity);
        setUpdateTrigger(prev => prev + 1);
      } catch (err) {
        setError(err.message);
      }
    },
    [entity]
  );

  const handlePin = useCallback(
    async itemId => {
      try {
        const activityLog = await ActivityLog.getById(itemId);
        await activityLog.update({ pinned: true });
        setPinnedFeedItem(activityLog);
        await fetchEntityData();
      } catch (err) {
        setError(err.message);
      }
    },
    [fetchEntityData]
  );

  const handleUnpin = useCallback(
    async itemId => {
      try {
        const activityLog = await ActivityLog.getById(itemId);
        await activityLog.update({ pinned: false });
        setPinnedFeedItem(null);
        await fetchEntityData();
      } catch (err) {
        setError(err.message);
      }
    },
    [fetchEntityData]
  );

  const handleFeedPageChange = useCallback(page => {
    setCurrentFeedPage(page);
  }, []);

  const handleNoteFeedPageChange = useCallback(page => {
    setCurrentNoteFeedPage(page);
  }, []);

  const handleOpenPipelinesPopup = useCallback(() => {
    setShowPipelinesPopup(true);
  }, []);

  const handleClosePipelinesPopup = useCallback(() => {
    setShowPipelinesPopup(false);
  }, []);

  const defaultTabs = [
    {
      label: 'Activities',
      path: 'activities',
      content: (
        <>
          {progressItems && (
            <div className='mb-6'>
              <ProgressBar
                title={progressItems.title}
                steps={progressItems.steps}
                progress={progressItems.getValue(entity)}
                colour='brand'
              />
            </div>
          )}
          {pinnedFeedItem && (
            <Alert
              className='mb-2'
              key={pinnedFeedItem.id}
              title='Pinned Item'
              description={`${pinnedFeedItem.owner.firstName} ${
                pinnedFeedItem.owner.lastName
              } on ${formatDate(pinnedFeedItem.createdDate, false, true)}
            ${pinnedFeedItem.content}`}
              style='warning'
              actions={[
                {
                  text: 'Unpin',
                  onClick: () => handleUnpin(pinnedFeedItem.id)
                }
              ]}
            />
          )}
          <div className='py-2' />
          {/* <h2 className='text-xl font-medium my-4'>Timeline</h2> */}
          <TabView
            default={'overview'}
            variant='pill'
            tabs={[
              {
                label: 'Overview',
                path: 'overview',
                content: (
                  <>
                    <Feed
                      items={feedItems}
                      onPin={handlePin}
                      onUnpin={handleUnpin}
                      logTypes={logTypes}
                    />
                    <Pagination
                      currentPage={currentFeedPage}
                      totalPages={totalFeedPages}
                      onPageChange={handleFeedPageChange}
                    />
                  </>
                )
              },
              {
                label: 'Notes',
                path: 'notes',
                content: (
                  <>
                    <Feed
                      items={noteFeedItems}
                      onPin={handlePin}
                      onUnpin={handleUnpin}
                    />
                    <Pagination
                      currentPage={currentNoteFeedPage}
                      totalPages={totalNoteFeedPages}
                      onPageChange={handleNoteFeedPageChange}
                    />
                  </>
                )
              },
              {
                label: 'Tasks',
                path: 'tasks',
                content: <TaskOverview entityType={entityType} entityId={id} />
              },
              {
                label: 'Meetings',
                path: 'meetings',
                content: <MeetingOverview meetings={meetings} />
              },
              {
                label: 'Files',
                path: 'files',
                content: (
                  <FileOverview
                    entityType={entityType}
                    id={id}
                    currentUser={currentUser}
                  />
                )
              }
            ]}
          />
        </>
      )
    }
  ];

  const allTabs = [...defaultTabs, ...additionalTabs];

  const menuItems = [{ label: 'Pipelines', onClick: handleOpenPipelinesPopup }];

  if (loading) return <LogoLoader logo={'/logo-star.png'} />;
  if (error) return <div>Error: {error}</div>;
  if (!entity) return <div>No entity found</div>;

  return (
    <div className='flex flex-col lg:flex-row h-[calc(100vh-48px)] overflow-auto lg:overflow-hidden'>
      <div className='w-full lg:w-112 mb-4 lg:mb-0 flex-shrink-0'>
        <EntityCard
          menuItems={menuItems}
          accordionItems={accordionItems}
          infoItems={infoItems}
          data={entity}
          onUpdate={handleUpdate}
          entityType={entityType}
          entityId={id}
          key={updateTrigger}
        />
      </div>
      <div className='lg:hidden h-8 w-full bg-base-50'></div>
      <div className='w-full lg:flex-1 lg:overflow-y-auto bg-white'>
        <TabView
          tabs={allTabs}
          key={updateTrigger}
          variant='underlined'
          default={'activities'}
        />
      </div>
      {showPipelinesPopup && (
        <ManagePipelines
          isOpen={showPipelinesPopup}
          onClose={handleClosePipelinesPopup}
          pipelines={entity.pipelines || []}
          entityId={id}
          entityType={entityType}
          entity={entity}
        />
      )}
    </div>
  );
};

export default Entity;
