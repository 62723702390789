import React from 'react';
import { ComboBox, Map } from 'core';
import Trainer from '../../../../models/Trainer';
import Location from '../../../../models/Location';
import { FilterOperatorEnum } from '../../../../utilities/Enumerables';

const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

const TrainerStage = ({ bookingData, handleInputChange }) => {
  return (
    <div className='space-y-6'>
      <ComboBox
        loadOptions={async (searchQuery, pageNumber) => {
          try {
            const { data: trainers } = await Trainer.getAll({
              fts: {
                operator: FilterOperatorEnum.TEXTSEARCH,
                value: searchQuery
              },
              page: pageNumber,
              pageSize: 10
            });
            return trainers.map(trainer => ({
              value: trainer.id,
              label: trainer.name,
              secondaryLabel: trainer.email
            }));
          } catch (error) {
            console.error('Error loading trainers:', error);
            return [];
          }
        }}
        label='Trainer'
        placeholder='Select a trainer'
        onChange={selectedTrainer => handleInputChange('trainer', selectedTrainer)}
        value={bookingData.trainer}
        className='w-full'
        colour='primary'
        required
      />
      {bookingData.deliveryType === '63' && (
        <>
          <ComboBox
            loadOptions={async (searchQuery, pageNumber) => {
              try {
                const { data: venues } = await Location.getAll({
                  fts: {
                    operator: FilterOperatorEnum.TEXTSEARCH,
                    value: searchQuery
                  },
                  location_type: { value: 108 },
                  entity_type: { value: 17 },
                  entity_id: { value: bookingData.trainer.value }
                });
                return venues.map(venue => ({
                  value: venue.id,
                  label: venue.name,
                  secondaryLabel: [
                    venue.address1,
                    venue.address2,
                    venue.city,
                    venue.county,
                    venue.postcode
                  ]
                    .filter(Boolean)
                    .join(', ')
                }));
              } catch (error) {
                console.error('Error loading venues:', error);
                return [];
              }
            }}
            label='Venue'
            placeholder='Select a venue'
            onChange={selectedVenue => handleInputChange('venue', selectedVenue)}
            value={bookingData.venue}
            className='w-full'
            colour='primary'
            hint={
              bookingData.venue
                ? bookingData.venue.secondaryLabel
                : 'If you know the exact venue please enter it here, otherwise leave this empty. You will be able to edit the venue at a later date.'
            }
            required
          />
          {bookingData.venue && (
            <Map apiKey={apiKey} address={bookingData.venue.secondaryLabel} />
          )}
        </>
      )}
    </div>
  );
};

export default TrainerStage;
