import React from 'react';

/**
 * Base Button Component
 *
 * @param {string} [props.colour='primary'] - The colour of the button ('brand', 'primary', 'info', 'success', 'warning', 'danger', 'base')
 * @param {string} [props.size='md'] - The size of the button ('xs', 'sm', 'md', 'lg', 'xl')
 * @param {React.ReactNode} props.children - The content to be rendered inside the button
 * @param {React.ReactNode} [props.leftIcon] - The icon to be rendered on the left side of the button
 * @param {React.ReactNode} [props.rightIcon] - The icon to be rendered on the right side of the button
 * @param {string} [props.className] - Additional CSS classes to be applied to the button
 * @param {Object} props - The properties passed to the component
 * @returns {React.ReactElement} A button element with the specified properties
 */
const BaseButton = ({
  colour = 'primary',
  size = 'md',
  disabled = false,
  leftIcon,
  children,
  rightIcon,
  className = '',
  variantClasses,
  ...props
}) => {
  const sizeClasses = {
    xs: 'rounded px-2 py-1 text-xs font-semibold gap-x-0.5',
    sm: 'rounded px-2 py-1 text-sm font-semibold gap-x-1',
    md: 'rounded-md px-2.5 py-1.5 text-sm font-semibold gap-x-1',
    lg: 'rounded-md px-3 py-2 text-sm font-semibold gap-x-1.5',
    xl: 'rounded-md px-3.5 py-2.5 text-sm font-semibold gap-x-1.5'
  };

  const iconSizes = {
    xs: 14,
    sm: 16,
    md: 18,
    lg: 20,
    xl: 22
  };

  const baseClasses =
    'font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2';
  const shadowClass = variantClasses.shadow ? 'shadow-sm' : '';
  const disabledClasses = disabled ? 'opacity-50 cursor-not-allowed' : '';

  const IconWrapper = ({ icon }) => {
    return React.cloneElement(icon, {
      size: iconSizes[size],
      className: `flex-shrink-0 ${icon.props.className || ''}`
    });
  };

  const renderContent = () => {
    if (
      React.Children.count(children) === 1 &&
      React.isValidElement(children)
    ) {
      return <IconWrapper icon={children} />;
    }
    return children;
  };

  return (
    <button
      type='button'
      className={`flex items-center ${sizeClasses[size]} ${variantClasses[colour]} ${baseClasses} ${shadowClass} ${disabledClasses} ${className}`}
      disabled={disabled}
      {...props}
    >
      {leftIcon && (
        <span className='-ml-0.5 mr-2'>
          <IconWrapper icon={leftIcon} />
        </span>
      )}
      {renderContent()}
      {rightIcon && (
        <span className='-mr-0.5 ml-2'>
          <IconWrapper icon={rightIcon} />
        </span>
      )}
    </button>
  );
};

const FilledButton = props => {
  const variantClasses = {
    brand: 'text-white bg-brand-600 hover:bg-brand-700',
    primary: 'text-white bg-primary-800 hover:bg-primary-600',
    info: 'text-white bg-info-600 hover:bg-info-700',
    success: 'text-white bg-success-600 hover:bg-success-700',
    warning: 'text-white bg-warning-600 hover:bg-warning-700',
    danger: 'text-white bg-danger-600 hover:bg-danger-700',
    base: 'text-base-800 bg-base-100 hover:bg-base-200',
    shadow: true
  };
  return <BaseButton {...props} variantClasses={variantClasses} />;
};

const SoftButton = props => {
  const variantClasses = {
    brand: 'text-brand-700 bg-brand-50 hover:bg-brand-100',
    primary: 'text-primary-700 bg-primary-50 hover:bg-primary-100',
    info: 'text-info-700 bg-info-50 hover:bg-info-100',
    success: 'text-success-700 bg-success-50 hover:bg-success-100',
    warning: 'text-warning-700 bg-warning-50 hover:bg-warning-100',
    danger: 'text-danger-700 bg-danger-50 hover:bg-danger-100',
    base: 'text-base-700 bg-base-50 hover:bg-base-100',
    shadow: true
  };
  return <BaseButton {...props} variantClasses={variantClasses} />;
};

const OutlineButton = props => {
  const variantClasses = {
    brand:
      'text-brand-600 border border-brand-600 hover:bg-brand-600 hover:text-white',
    primary:
      'text-primary-800 border border-primary-800 hover:bg-primary-800 hover:text-white',
    info: 'text-info-600 border border-info-600 hover:bg-info-600 hover:text-white',
    success:
      'text-success-600 border border-success-600 hover:bg-success-600 hover:text-white',
    warning:
      'text-warning-600 border border-warning-600 hover:bg-warning-600 hover:text-white',
    danger:
      'text-danger-600 border border-danger-600 hover:bg-danger-600 hover:text-white',
    base: 'text-base-800 border border-base-800 hover:bg-base-800 hover:text-white',
    shadow: true
  };
  return <BaseButton {...props} variantClasses={variantClasses} />;
};

const TextButton = props => {
  const variantClasses = {
    brand: 'text-brand-600 hover:underline',
    primary: 'text-primary-800 hover:underline',
    info: 'text-info-600 hover:underline',
    success: 'text-success-600 hover:underline',
    warning: 'text-warning-600 hover:underline',
    danger: 'text-danger-600 hover:underline',
    base: 'text-base-800 hover:underline',
    shadow: false
  };
  return <BaseButton {...props} variantClasses={variantClasses} />;
};

const CircularIconButton = React.forwardRef(
  (
    { Icon, label, onClick, colour = 'brand', className = '', ...props },
    ref
  ) => {
    const variantClasses = {
      brand:
        'bg-brand-100 text-brand-600 hover:bg-brand-700 hover:text-white border border-brand-300',
      primary:
        'bg-primary-100 text-primary-600 hover:bg-primary-700 hover:text-white border border-primary-300',
      info: 'bg-info-100 text-info-600 hover:bg-info-700 hover:text-white border border-info-300',
      success:
        'bg-success-100 text-success-600 hover:bg-success-700 hover:text-white border border-success-300',
      warning:
        'bg-warning-100 text-warning-600 hover:bg-warning-700 hover:text-white border border-warning-300',
      danger:
        'bg-danger-100 text-danger-600 hover:bg-danger-700 hover:text-white border border-danger-300',
      base: 'bg-base-100 text-base-600 hover:bg-base-700 hover:text-white border border-base-300'
    };

    return (
      <button
        ref={ref}
        className={`flex flex-col items-center justify-center p-2 rounded-lg ${className}`}
        onClick={onClick}
        {...props}
      >
        <div
          className={`w-10 h-10 flex items-center justify-center rounded-full mb-1 transition-colors duration-300 ${variantClasses[colour]}`}
        >
          <Icon size={20} />
        </div>
        {label && <span className='text-xs text-neutral-600'>{label}</span>}
      </button>
    );
  }
);

// Add a display name for the forwardRef component
CircularIconButton.displayName = 'CircularIconButton';

export {
  FilledButton,
  SoftButton,
  OutlineButton,
  TextButton,
  CircularIconButton
};
