import React, { useState, useEffect } from 'react';
import { Popup, Input, DropdownInput, FilledButton } from 'core';
import AdditionalCharge from '../../../models/AdditionalCharge';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const AddCharge = ({ isOpen, onClose, bookingId, onChargeAdded }) => {
  const [charge, setCharge] = useState({
    description: '',
    chargeTypeId: '',
    statusId: '',
    vatRate: '',
    amount: ''
  });
  const [chargeTypes, setChargeTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const chargeTypeOptions = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.AdditionalChargeType
        );
        const statusOptions = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.AdditionalChargeStatus
        );

        setChargeTypes(
          chargeTypeOptions.map(option => ({
            value: option.id,
            label: option.type
          }))
        );
        setStatuses(
          statusOptions.map(option => ({
            value: option.id,
            label: option.type
          }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleInputChange = e => {
    const { name, value } = e.target;
    let tempVal = value;
    // If the charge type is a credit note, convert it to a negative number
    if (name == 'amount' && charge.chargeTypeId == 134 && tempVal > 0) {
      tempVal = 0 - value;
    }
    if (charge.chargeTypeId == 134 && charge.amount > 0) {
      setCharge(prev => ({ ...prev, amount: 0 - charge.amount }));
    }
    setCharge(prev => ({ ...prev, [name]: tempVal }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const newCharge = new AdditionalCharge({
        booking: bookingId,
        description: charge.description,
        charge_type: { id: charge.chargeTypeId },
        status: { id: charge.statusId },
        vat_rate: charge.vatRate,
        amount: charge.amount
      });
      await newCharge.insert();
      onChargeAdded(newCharge);
      onClose();
    } catch (error) {
      console.error('Error adding charge:', error);
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Add Additional Charge'>
      <form onSubmit={handleSubmit} className='space-y-4'>
        <Input
          label='Description'
          name='description'
          value={charge.description}
          onChange={handleInputChange}
        />
        <DropdownInput
          label='Charge Type'
          name='chargeTypeId'
          value={charge.chargeTypeId}
          onChange={handleInputChange}
          options={chargeTypes}
          required
        />
        <DropdownInput
          label='Status'
          name='statusId'
          value={charge.statusId}
          onChange={handleInputChange}
          options={statuses}
          required
        />
        <Input
          label='VAT Rate'
          name='vatRate'
          type='number'
          value={charge.vatRate}
          onChange={handleInputChange}
          required
        />
        <Input
          label='Amount'
          name='amount'
          type='number'
          value={charge.amount}
          onChange={handleInputChange}
          required
          hint={
            charge.chargeTypeId == 134
              ? 'Credit notes are automatically converted to a negative value.'
              : ''
          }
        />
        <div className='flex justify-end space-x-2'>
          <FilledButton type='button' onClick={onClose} colour='base'>
            Cancel
          </FilledButton>
          <FilledButton type='submit' colour='primary'>
            Add Charge
          </FilledButton>
        </div>
      </form>
    </Popup>
  );
};

export default AddCharge;
