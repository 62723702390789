import React, { useState, useEffect } from 'react';
import AdditionalCharge from '../../../../models/AdditionalCharge';
import EntityTable from '../../../../components/tables/EntityTable';
import { formatDate } from '../../../../utilities/Formatting';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';
import CoreEntityTypes from '../../../../models/CoreEntityType';
import AddCharge from '../../../../components/popups/bookings/AddCharge';
import CreateInvoice from '../../../../components/popups/bookings/CreateInvoice';
import { SoftButton } from 'core';

const AdditionalCharges = ({ bookingId }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [chargeTypeOptions, setChargeTypeOptions] = useState([]);
  const [isAddChargeOpen, setIsAddChargeOpen] = useState(false);
  const [isCreateInvoiceOpen, setIsCreateInvoiceOpen] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const statusTypes = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.AdditionalChargeStatus
        );
        const chargeTypes = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.AdditionalChargeType
        );

        setStatusOptions(
          statusTypes.map(option => ({
            key: option.id,
            value: option.type
          }))
        );

        setChargeTypeOptions(
          chargeTypes.map(option => ({
            key: option.id,
            value: option.type
          }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/additional-charge/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/additional-charge/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleNewClick = () => {
    setIsAddChargeOpen(true);
  };

  const handleCloseAddCharge = () => {
    setIsAddChargeOpen(false);
  };

  const handleChargeAdded = newCharge => {
    // Handle the newly added charge, e.g., refresh the table
    console.log('New charge added:', newCharge);
  };

  const handleOpenCreateInvoice = () => {
    setIsCreateInvoiceOpen(true);
  };

  const handleCloseCreateInvoice = () => {
    setIsCreateInvoiceOpen(false);
  };

  return (
    <>
      <EntityTable
        name='Additional Charges'
        filter={[
          { field: 'id', label: 'ID', type: 'number' },
          { field: 'invoice_id', label: 'Invoice ID', type: 'number' },
          { field: 'description', label: 'Description', type: 'text' },
          {
            field: 'charge_type',
            label: 'Charge Type',
            type: 'select',
            options: chargeTypeOptions
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions
          },
          { field: 'created_date', label: 'Created Date', type: 'date' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id'
          },
          {
            label: 'Invoice ID',
            field: 'invoiceId',
            cell: (value, row) => {
              if (value) {
                return value;
              } else {
                return (
                  <SoftButton
                    colour='primary'
                    size='sm'
                    onClick={e => {
                      e.stopPropagation();
                      handleOpenCreateInvoice();
                    }}
                  >
                    Create
                  </SoftButton>
                );
              }
            }
          },
          { label: 'Description', field: 'description' },
          {
            label: 'Charge Type',
            field: 'chargeType.name',
            foreignKey: { table: 'charge_type', column: 'type' }
          },
          {
            label: 'Status',
            field: 'status.name',
            foreignKey: { table: 'status', column: 'type' }
          },
          { label: 'VAT Rate', field: 'vatRate' },
          {
            label: 'Amount',
            field: 'amount'
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={AdditionalCharge}
        entityType={EntityTypeEnum.AdditionalCharge}
        defaultFilters={{ booking: { value: bookingId } }}
        onNewClick={handleNewClick}
        canCreate={true}
      />
      <AddCharge
        isOpen={isAddChargeOpen}
        onClose={handleCloseAddCharge}
        bookingId={bookingId}
        onChargeAdded={handleChargeAdded}
      />
      <CreateInvoice
        isOpen={isCreateInvoiceOpen}
        onClose={handleCloseCreateInvoice}
        bookingId={bookingId}
      />
    </>
  );
};

export default AdditionalCharges;
