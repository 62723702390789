import React, { useState, useEffect } from 'react';
import {
  FilledButton,
  OutlineButton,
  Badge,
  TextButton,
  DropdownMenu
} from 'core';
import TaskModel from '../../../models/Task';
import Popup from '../Popup';
import TabView from '../../navigation/TabView';
import Details from './Details';
import Comments from './Comments';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams,
  useLocation
} from 'react-router-dom';
import { MoreVertical } from 'lucide-react';

const TaskPopup = ({ task: initialTask, onClose }) => {
  const [task, setTask] = useState(initialTask);
  const [editedTask, setEditedTask] = useState({ ...initialTask });
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { '*': wildcard } = useParams();

  useEffect(() => {
    if (!wildcard) {
      navigate(`details`, { replace: true });
    }
  }, [wildcard, navigate]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const updatedTask = await task.update(editedTask);
      setTask(updatedTask);
      onClose();
    } catch (error) {
      console.error('Error updating task:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const tabs = [
    {
      label: 'Details',
      path: `${task.id}/details`,
      content: <Details editedTask={editedTask} setEditedTask={setEditedTask} />
    },
    {
      label: 'Comments',
      path: `${task.id}/comments`,
      content: <Comments taskId={task.id} />
    }
  ];

  const handleDropdownChange = value => {
    if (value === 'add_child') {
      // Handle adding child task
    } else if (value === 'mark_complete') {
      // Handle marking task as complete
    }
    setIsDropdownOpen(false);
  };

  return (
    <Popup title={editedTask.title} onClose={onClose}>
      <div className='space-y-4'>
        <TabView
          tabs={tabs}
          baseUrl={`${task.id}`}
          fullWidth={true}
          default={'details'}
        />
        <div className='flex justify-end space-x-2'>
          <TextButton colour='base' onClick={onClose}>
            Cancel
          </TextButton>
          <FilledButton
            colour='primary'
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </FilledButton>
        </div>
      </div>
    </Popup>
  );
};

export default TaskPopup;
