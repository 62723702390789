import React, { useState, useEffect } from 'react';
import Booking from '../../../models/Booking';
import CoreEntityType from '../../../models/CoreEntityType';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Link, useNavigate } from 'react-router-dom';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { useContext } from 'react';
import { UserProfileContext } from '../../../App';
import UserProfile from '../../../models/UserProfile';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { Alert } from 'core';

const Bookings = () => {
  //Set the page title
  document.title = 'Bookings | CRM | BOHSA LTD';
  const [statusOptions, setStatusOptions] = useState([]);
  const [subStatusOptions, setSubStatusOptions] = useState([]);
  const navigate = useNavigate();

  const currentUser = new UserProfile(
    useContext(UserProfileContext).userProfile
  );

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.BookingStatus }
        });
        const { data: subStatuses } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.BookingSubStatus }
        });
        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
        setSubStatusOptions(
          subStatuses.map(status => ({ key: status.id, value: status.type }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/bookings/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/bookings/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleNewClick = () => {
    navigate('/bookings/create');
  };

  const handleRowClick = data => {
    navigate(`/bookings/${data.id}`);
  };

  //Test user permissions for view access
  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Booking,
      PERMISSIONS[EntityTypeEnum.Booking].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view bookings.'
        style='danger'
      />
    );
  }

  return (
    <div className='p-4'>
      <EntityTable
        name='Bookings'
        filter={[
          { field: 'id', label: 'ID', type: 'number' },
          {
            field: 'companies.name',
            label: 'Company Name',
            type: 'text',
            operator: 'ilike'
          },
          {
            field: 'trainers.name',
            label: 'Trainer Name',
            type: 'text',
            operator: 'ilike'
          },
          {
            field: 'courses.name',
            label: 'Course Name',
            type: 'text',
            operator: 'ilike'
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions
          },
          {
            field: 'sub_status',
            label: 'Sub Status',
            type: 'select',
            options: subStatusOptions
          },
          { field: 'created_date', label: 'Created From', type: 'date' },
          { field: 'created_date', label: 'Created To', type: 'date' },
          { field: 'start_date', label: 'Start Date From', type: 'date' },
          { field: 'start_date', label: 'Start Date To', type: 'date' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/bookings/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          {
            label: 'Company',
            field: 'company.name',
            foreignKey: { table: 'companies', column: 'name' }
          },
          {
            label: 'Trainer',
            field: 'trainer.name',
            foreignKey: { table: 'trainers', column: 'name' }
          },
          {
            label: 'Course',
            field: 'course.name',
            foreignKey: { table: 'courses', column: 'name' }
          },
          {
            label: 'Status',
            field: 'status.name',
            foreignKey: { table: 'status', column: 'type' }
          },
          { label: 'Spaces', field: 'spaces' },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Booking Date',
            field: 'bookingDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Booking}
        entityType={EntityTypeEnum.Booking}
        onRowClick={handleRowClick}
        onNewClick={handleNewClick}
        canCreate={true}
      />
    </div>
  );
};

export default Bookings;
