import BaseModel from './BaseModel';

/**
 * Represents an Agreed Rate in the system.
 * @extends BaseModel
 */
class AgreedRate extends BaseModel {
  /** @type {string} The name of the database table for Agreed Rates */
  static table = 'agreed_rates';

  /** @type {string} The SQL query to select Agreed Rate data with related information */
  static selectQuery = `
    *,
    companies (id, name),
    delivery:core_entity_types!delivery_id (id, type),
    courses (id, name),
    trainers (id, name),
    core_entities (id, display_name),
    status:core_entity_types!status (id, type)
  `;

  /**
   * Creates an instance of AgreedRate.
   * @param {Object} data - The agreed rate data.
   */
  constructor(data = {}) {
    super(data);
    this.company = data.company_id
      ? { id: data.company_id, name: data.companies?.name }
      : null;
    this.delivery = data.delivery_id
      ? { id: data.delivery_id, type: data.delivery?.type }
      : null;
    this.course = data.course_id
      ? { id: data.course_id, name: data.courses?.name }
      : null;
    this.trainer = data.trainer_id
      ? { id: data.trainer_id, name: data.trainers?.name }
      : null;
    this.entityType = data.entity_type
      ? { id: data.entity_type, name: data.core_entities?.name }
      : null;
    this.entityId = data.entity_id || null;
    this.price = data.price || null;
    this.status = data.status
      ? { id: data.status, type: data.status?.type }
      : null;
    this.createdDate = data.created_date || null;
    this.updatedDate = data.updated_date || null;
  }

  /**
   * Converts the AgreedRate instance to a database-friendly format.
   * @returns {Object} The agreed rate data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      company_id: this.company ? this.company.id : null,
      delivery_id: this.delivery ? this.delivery.id : null,
      course_id: this.course ? this.course.id : null,
      trainer_id: this.trainer ? this.trainer.id : null,
      entity_type: this.entityType ? this.entityType.id : null,
      entity_id: this.entityId,
      price: this.price,
      status: this.status ? this.status.id : null,
      created_date: this.createdDate,
      updated_date: this.updatedDate
    };
  }
}

export default AgreedRate;

