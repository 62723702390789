import BaseModel from './BaseModel';

/**
 * Represents a BookingSpace in the system.
 * @extends BaseModel
 */
class BookingSpace extends BaseModel {
  /** @type {string} The name of the database table for BookingSpaces */
  static table = 'booking_spaces';

  static selectQueryWithDelegate = `
    *,
    booking:bookings (id, company_id),
    delegate:user_profiles!inner(id, first_name, last_name, full_name),
    status:core_entity_types!booking_space_status_fkey (id, type),
    levy_status:core_entity_types!booking_space_levy_status_fkey (id, type)
  `;

  static selectQueryWithoutDelegate = `
    *,
    booking:bookings (id, company_id),
    status:core_entity_types!booking_space_status_fkey (id, type),
    levy_status:core_entity_types!booking_space_levy_status_fkey (id, type)
  `;

  static selectQuery = BookingSpace.selectQueryWithDelegate;

  /**
   * Creates an instance of BookingSpace.
   * @param {Object} data - The booking space data.
   */
  constructor(data = {}) {
    super(data);
    this.booking = data.booking ? { id: data.booking.id } : null;
    this.companyId = data.booking ? data.booking.company_id : null;
    this.delegate = data.delegate
      ? {
          id: data.delegate.id,
          firstName: data.delegate.first_name,
          lastName: data.delegate.last_name,
          fullName: data.delegate.full_name
        }
      : null;
    this.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    this.levyStatus = data.levy_status
      ? { id: data.levy_status.id, name: data.levy_status.type }
      : null;
    this.completionDate = data.completion_date || null;
    this.expiryDate = data.expiry_date || null;
  }

  /**
   * Converts the BookingSpace instance to a database-friendly format.
   * @returns {Object} The booking space data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      booking_id: this.booking ? this.booking.id : null,
      delegate_id: this.delegate ? this.delegate.id : null,
      status: this.status ? this.status.id : null,
      levy_status: this.levyStatus ? this.levyStatus.id : null,
      completion_date: this.completionDate,
      expiry_date: this.expiryDate
    };
  }

  static async getAll(
    filters = {},
    page = 1,
    size = 10,
    sortBy = '',
    sortType = 'asc',
    includeDelegate = true
  ) {
    this.selectQuery = includeDelegate
      ? this.selectQueryWithDelegate
      : this.selectQueryWithoutDelegate;
    const result = await super.getAll(filters, page, size, sortBy, sortType);
    this.selectQuery = this.selectQueryWithDelegate; // Reset to default
    return result;
  }
}

export default BookingSpace;
