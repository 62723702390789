import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext
} from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { supabase } from '../../../utilities/supabase';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { formatProfile } from '../../../utilities/Formatting';
import {
  FilledButton,
  TextButton,
  OutlineButton,
  SoftButton,
  Alert,
  Tooltip
} from 'core';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import {
  Plus,
  Ellipsis,
  MoreVertical,
  Eye,
  Trash,
  Settings,
  Hash,
  User,
  Mail,
  Phone,
  Building,
  Calendar,
  CalendarPlus,
  Pin,
  GitBranch,
  UserCheck
} from 'lucide-react';
import CreatePipeline from '../../../components/popups/pipelines/CreatePipeline';
import EditPipeline from '../../../components/popups/pipelines/EditPipeline';
import AddPipelineItem from '../../../components/popups/pipelines/AddPipelineItem';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import CreateBasicPipeline from '../../../components/popups/pipelines/CreateBasicPipeline';
import { UserProfileContext } from '../../../App';
import UserProfile from '../../../models/UserProfile';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { useNotification } from '../../../../core/src/components/providers/NotificationProvider';

const iconMap = {
  Hash: Hash,
  User: User,
  Mail: Mail,
  Phone: Phone,
  Building: Building,
  Calendar: Calendar,
  CalendarPlus: CalendarPlus,
  Pin: Pin,
  GitBranch: GitBranch,
  UserCheck: UserCheck
};

const Pipelines = () => {
  const [pipelines, setPipelines] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [columns, setColumns] = useState({});
  const [items, setItems] = useState([]);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showBasicPopup, setShowBasicPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showAddItemPopup, setShowAddItemPopup] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);
  const { addNotification } = useNotification();
  const currentUser = new UserProfile(userProfile);

  useEffect(() => {
    let isMounted = true;
    const fetchPipelines = async () => {
      try {
        const userId = currentUser.id;

        const { data, error } = await supabase
          .from('pipelines')
          .select(
            '*, owner:user_profiles!owner(id,first_name,last_name,full_name,profile_photo)'
          )
          .or(`private.eq.false,and(private.eq.true,owner.eq.${userId})`)
          .order('title', { ascending: true });

        if (error) throw error;
        if (isMounted) {
          setPipelines(prevPipelines => {
            // Only update if the data has actually changed
            if (JSON.stringify(prevPipelines) !== JSON.stringify(data)) {
              return data;
            }
            return prevPipelines;
          });
        }
      } catch (error) {
        console.error('Error fetching pipelines:', error.message);
      }
    };

    if (currentUser.id) {
      fetchPipelines();
    }

    return () => {
      isMounted = false;
    };
  }, [currentUser.id]);

  useEffect(() => {
    if (pipelines.length > 0 && id) {
      const pipeline = pipelines.find(p => p.id === parseInt(id));
      if (pipeline) {
        setSelectedPipeline(pipeline);
      }
    }
  }, [pipelines, id]);

  const fetchItems = useCallback(async pipeline => {
    try {
      const { data: entityData } = await supabase
        .from('core_entities')
        .select('table_name')
        .eq('id', pipeline.entity)
        .single();

      const { data, error } = await supabase
        .from(entityData.table_name)
        .select(pipeline.columns ?? '*')
        .contains('pipelines', JSON.stringify([{ id: pipeline.id }]));

      if (error) throw error;

      const newItems = data.map(item => {
        const pipelineInfo = item.pipelines.find(p => p.id === pipeline.id);
        return {
          id: item.id,
          content: renderItemContent(
            item,
            entityData.table_name,
            pipeline.config
          ),
          group: pipelineInfo.group,
          order: pipelineInfo.order
        };
      });

      setItems(newItems);
      updateColumns(newItems, pipeline.groups);
    } catch (error) {
      console.error('Error fetching items:', error.message);
    }
  }, []);

  const updateColumns = useCallback((newItems, groups) => {
    setColumns(prevColumns => {
      const updatedColumns = {};
      groups.forEach(group => {
        updatedColumns[group.id] = {
          id: group.id,
          name: group.label,
          items: newItems
            .filter(item => item.group === group.id)
            .sort((a, b) => a.order - b.order),
          colour: group.colour
        };
      });
      return updatedColumns;
    });
  }, []);

  useEffect(() => {
    if (selectedPipeline) {
      fetchItems(selectedPipeline);
    }
  }, [selectedPipeline, fetchItems]);

  const onDragEnd = useCallback(
    async result => {
      if (!result.destination) return;
      const { source, destination, draggableId } = result;

      if (source.droppableId === destination.droppableId) {
        setColumns(prevColumns => {
          const newColumns = { ...prevColumns };
          const column = newColumns[source.droppableId];
          const newItems = Array.from(column.items);
          const [reorderedItem] = newItems.splice(source.index, 1);
          newItems.splice(destination.index, 0, reorderedItem);
          newColumns[source.droppableId] = {
            ...column,
            items: newItems
          };
          return newColumns;
        });
      } else {
        setColumns(prevColumns => {
          const newColumns = { ...prevColumns };
          const sourceColumn = newColumns[source.droppableId];
          const destColumn = newColumns[destination.droppableId];
          const sourceItems = [...sourceColumn.items];
          const destItems = [...destColumn.items];
          const [movedItem] = sourceItems.splice(source.index, 1);
          destItems.splice(destination.index, 0, movedItem);
          newColumns[source.droppableId] = {
            ...sourceColumn,
            items: sourceItems
          };
          newColumns[destination.droppableId] = {
            ...destColumn,
            items: destItems
          };
          return newColumns;
        });
      }

      updateDatabase(draggableId, destination);
    },
    [items, selectedPipeline]
  );

  const updateDatabase = async (draggableId, destination) => {
    try {
      const item = items.find(i => i.id.toString() === draggableId);
      if (!item) return;

      const { data: entityData } = await supabase
        .from('core_entities')
        .select('table_name')
        .eq('id', selectedPipeline.entity)
        .single();

      const { data: entityItem } = await supabase
        .from(entityData.table_name)
        .select('pipelines')
        .eq('id', item.id)
        .single();

      const updatedPipelines = entityItem.pipelines.map(p => {
        if (p.id === selectedPipeline.id) {
          return {
            ...p,
            group: parseInt(destination.droppableId),
            order: destination.index
          };
        }
        return p;
      });

      const { data, error } = await supabase
        .from(entityData.table_name)
        .update({ pipelines: updatedPipelines })
        .eq('id', item.id)
        .select();

      console.log(data);

      if (error) throw error;

      // Update other items in the same column
      const columnItems = columns[destination.droppableId].items;
      for (let i = 0; i < columnItems.length; i++) {
        if (columnItems[i].id !== item.id) {
          const { data: currentItem } = await supabase
            .from(entityData.table_name)
            .select('pipelines')
            .eq('id', columnItems[i].id)
            .single();

          const updatedPipelines = currentItem.pipelines.map(p => {
            if (p.id === selectedPipeline.id) {
              return {
                ...p,
                order: i >= destination.index ? i + 1 : i
              };
            }
            return p;
          });

          await supabase
            .from(entityData.table_name)
            .update({ pipelines: updatedPipelines })
            .eq('id', columnItems[i].id);
        }
      }
    } catch (error) {
      console.error('Error updating items:', error.message);
    }
  };

  const removeItemFromPipeline = async (itemId, tableName) => {
    try {
      const { data: entityItem } = await supabase
        .from(tableName)
        .select('pipelines')
        .eq('id', itemId)
        .single();

      const updatedPipelines = entityItem.pipelines.filter(p => p.id != id);

      const { error } = await supabase
        .from(tableName)
        .update({ pipelines: updatedPipelines })
        .eq('id', itemId);

      if (error) throw error;

      // Update local state
      setItems(prevItems => prevItems.filter(item => item.id !== itemId));
      setColumns(prevColumns => {
        const newColumns = { ...prevColumns };
        Object.keys(newColumns).forEach(columnId => {
          newColumns[columnId].items = newColumns[columnId].items.filter(
            item => item.id !== itemId
          );
        });
        return newColumns;
      });
    } catch (error) {
      console.error('Error removing item from pipeline:', error.message);
    }
  };

  const renderBoardView = useMemo(
    () => (
      <DragDropContext onDragEnd={onDragEnd}>
        <div className='flex space-x-2 h-full'>
          {Object.entries(columns).map(([columnId, column]) => (
            <div
              key={columnId}
              className={`w-72 flex-shrink-0 flex flex-col h-full rounded-md bg-${column.colour}-50 bg-opacity-50 p-2`}
            >
              <div className='flex justify-between group items-center mb-4'>
                <p
                  className={`font-medium text-xs bg-${column.colour}-600 text-white px-2 py-1 rounded-md flex items-center`}
                >
                  <span>{column.name}</span>
                  <span className='text-xs text-white ml-2 bg-opacity-50 bg-white px-1.5 py-0.5 rounded-full'>
                    {column.items.length}
                  </span>
                </p>
                <div className='flex items-center'>
                  {currentUser.hasPermission(
                    EntityTypeEnum.Pipelines,
                    PERMISSIONS[EntityTypeEnum.Pipelines].EDIT
                  ) && (
                    <TextButton
                      size='sm'
                      onClick={() => setShowEditPopup(true)}
                    >
                      <Ellipsis size={16} className='text-base-600' />
                    </TextButton>
                  )}
                  <TextButton
                    size='sm'
                    onClick={() => {
                      setSelectedGroupId(columnId);
                      setShowAddItemPopup(true);
                    }}
                  >
                    <Plus size={16} className='text-base-600' />
                  </TextButton>
                </div>
              </div>
              <div className='overflow-y-auto flex-grow'>
                <Droppable droppableId={columnId} type='item'>
                  {provided => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className='min-h-[200px] space-y-2'
                    >
                      {column.items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id.toString()}
                          index={index}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className='bg-white dark:bg-base-700 p-1 rounded-lg text-sm border border-base-200 dark:border-base-600 hover:shadow-md transition-shadow duration-200'
                            >
                              {item.content}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          ))}
        </div>
      </DragDropContext>
    ),
    [columns, onDragEnd]
  );

  const renderItemContent = (item, tableName, config) => (
    <div className='relative p-2'>
      <div className='absolute right-2 top-2'>
        <Menu as='div' className='relative inline-block text-left items-center'>
          <div>
            <MenuButton as={TextButton}>
              <span className='sr-only'>Options</span>
              <MoreVertical className='-mr-4 ml-1 h-4 w-4' aria-hidden='true' />
            </MenuButton>
          </div>
          <MenuItems className='absolute right-0 w-42 origin-top-right bg-white divide-y divide-base-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='px-1 py-1'>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-primary-100 text-base-900' : 'text-base-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => {
                      const route =
                        tableName === 'user_profiles' ? 'users' : tableName;
                      window.open(`/${route}/${item.id}`, '_blank');
                    }}
                  >
                    <Eye size={18} className='mr-2 flex-shrink-0' aria-hidden='true' />
                    View
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-primary-100 text-base-900' : 'text-base-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => removeItemFromPipeline(item.id, tableName)}
                  >
                    <Trash
                      size={18}
                      className='mr-2 text-danger-600 flex-shrink-0'
                      aria-hidden='true'
                    />
                    Remove
                  </button>
                )}
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </div>
      {Object.entries(config)
        .sort(([, a], [, b]) => (a.order || 0) - (b.order || 0))
        .map(([key, value]) => {
          if (key === 'pipelines' || key === 'id' || !item.hasOwnProperty(key))
            return null;
          return (
            <div
              key={key}
              className={`mb-3 ${
                value?.inline ? 'inline-block mr-4' : 'block'
              }`}
            >
              {value?.labelStyle !== 'hidden' && (
                <label
                  className={`text-xs font-medium text-gray-700 dark:text-gray-300 mb-1 mr-2 ${
                    value?.labelStyle === 'iconOnly' ? 'sr-only' : ''
                  }`}
                >
                  {value?.icon &&
                    React.createElement(iconMap[value.icon], {
                      size: 14,
                      className: 'inline-block mr-2 text-base-500 flex-shrink-0'
                    })}
                  {value?.labelStyle !== 'iconOnly' &&
                    key
                      .split('_')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')}
                </label>
              )}
              <Tooltip
                content={key
                  .split('_')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
                className='text-xs'
              >
                <div
                  className={`text-sm ${
                    value?.labelStyle === 'iconOnly'
                      ? 'inline-flex items-center'
                      : ''
                  }`}
                >
                  {value?.labelStyle === 'iconOnly' &&
                    value?.icon &&
                    React.createElement(iconMap[value.icon], {
                      size: 14,
                      className: 'inline-block mr-2 text-base-500 flex-shrink-0'
                    })}
                  <div className="flex-grow">{renderValue(item[key])}</div>
                </div>
              </Tooltip>
            </div>
          );
        })}
    </div>
  );

  const renderValue = value => {
    if (value === null || value === '')
      return <span className='text-gray-400'>N/A</span>;
    if (typeof value === 'object' && value !== null) {
      if (value.first_name) {
        return formatProfile(
          {
            firstName: value.first_name,
            lastName: value.last_name,
            profilePhoto: value.profile_photo
          },
          'sm'
        );
      } else if (value.name) {
        return value.name;
      } else if (value[0] && value[0].type) {
        return (
          <div>
            {value.map((phone, index) => (
              <div key={index} className='flex items-center mb-1'>
                <span className='text-xs font-medium text-gray-500 mr-2 capitalize'>
                  {phone.type}:
                </span>
                <span className='text-sm'>{phone.number}</span>
              </div>
            ))}
          </div>
        );
      } else {
        return JSON.stringify(value);
      }
    }
    if (typeof value === 'string') {
      if (!isNaN(Date.parse(value))) {
        return new Date(value).toLocaleDateString();
      }
      // Check if the value contains HTML tags
      if (/<[a-z][\s\S]*>/i.test(value)) {
        return <div dangerouslySetInnerHTML={{ __html: value }} />;
      }
    }
    return String(value);
  };

  const handlePipelineCreated = newPipeline => {
    setPipelines([newPipeline, ...pipelines]);
    setSelectedPipeline(newPipeline);
    navigate(`/pipelines/${newPipeline.id}`);
  };

  const handleBasicPipelineCreated = async newPipeline => {
    try {
      const { data, error } = await supabase
        .from('pipelines')
        .insert(newPipeline)
        .select()
        .single();

      if (error) throw error;

      setPipelines([data, ...pipelines]);
      setSelectedPipeline(data);
      navigate(`/pipelines/${data.id}`);
    } catch (error) {
      console.error('Error creating basic pipeline:', error.message);
    }
  };

  const handlePipelineEdit = async editedPipeline => {
    try {
      const { owner, ...pipelineWithoutOwner } = editedPipeline;
      const { data, error } = await supabase
        .from('pipelines')
        .update({ ...pipelineWithoutOwner, owner: owner.id })
        .eq('id', editedPipeline.id)
        .select();

      if (error) throw error;

      const updatedPipelines = pipelines.map(p =>
        p.id === editedPipeline.id ? data[0] : p
      );
      setPipelines(updatedPipelines);
      setSelectedPipeline(data[0]);
    } catch (error) {
      console.error('Error updating pipeline:', error.message);
      addNotification({
        type: 'error',
        title: 'Error updating the pipeline',
        description: error.message,
        duration: 4000
      });
    }
  };

  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Pipelines,
      PERMISSIONS[EntityTypeEnum.Pipelines].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view pipelines.'
        style='danger'
      />
    );
  }

  return (
    <div className='flex flex-col h-[calc(100vh-48px)] w-full bg-white dark:bg-base-900 overflow-hidden'>
      <div className='flex flex-1 flex-col md:flex-row overflow-hidden'>
        <div className='w-full md:w-16 lg:w-64 flex-shrink-0 bg-white border-r border-base-100 dark:bg-base-800 p-4'>
          <div className='hidden md:flex md:flex-col md:space-y-4 md:items-stretch'>
            {currentUser.hasPermission(
              EntityTypeEnum.Pipelines,
              PERMISSIONS[EntityTypeEnum.Pipelines].CREATE
            ) && (
              <SoftButton
                onClick={() => setShowBasicPopup(true)}
                className='w-full mb-4'
              >
                <Plus className='mr-2' size={16} />
                New Pipeline
              </SoftButton>
            )}
            <div>
              <h3 className='text-sm font-semibold text-base-500 dark:text-base-400 mb-2'>
                Global Pipelines
              </h3>
              <div className='space-y-1'>
                {pipelines
                  .filter(p => !p.private && !p.archived)
                  .map(pipeline => (
                    <NavLink
                      key={pipeline.id}
                      to={`/pipelines/${pipeline.id}`}
                      className={({ isActive }) => `
                      px-2 py-1.5 rounded-md transition-colors duration-200 w-full flex items-center space-x-2
                      ${
                        isActive
                          ? 'bg-primary-100 text-base-950'
                          : 'hover:bg-base-100 dark:hover:bg-base-700 text-base-700 dark:text-base-300'
                      }
                    `}
                    >
                      <span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.75rem] font-medium'>
                        {pipeline.title[0]}
                      </span>
                      <span className='hidden lg:block text-sm truncate'>
                        {pipeline.title}
                      </span>
                    </NavLink>
                  ))}
              </div>
            </div>

            {pipelines.some(p => p.private && !p.archived) && (
              <div>
                <h3 className='text-sm font-semibold text-base-500 dark:text-base-400 mb-2'>
                  My Pipelines
                </h3>
                <div className='space-y-1'>
                  {pipelines
                    .filter(p => p.private && !p.archived)
                    .map(pipeline => (
                      <NavLink
                        key={pipeline.id}
                        to={`/pipelines/${pipeline.id}`}
                        className={({ isActive }) => `
                        px-2 py-1.5 rounded-md transition-colors duration-200 w-full flex items-center space-x-2
                        ${
                          isActive
                            ? 'bg-primary-100 text-base-950'
                            : 'hover:bg-base-100 dark:hover:bg-base-700 text-base-700 dark:text-base-300'
                        }
                      `}
                      >
                        <span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.75rem] font-medium'>
                          {pipeline.title[0]}
                        </span>
                        <span className='hidden lg:block text-sm truncate'>
                          {pipeline.title}
                        </span>
                      </NavLink>
                    ))}
                </div>
              </div>
            )}

            {pipelines.some(p => p.archived) && (
              <div>
                <h3 className='text-sm font-semibold text-base-500 dark:text-base-400 mb-2'>
                  Archived
                </h3>
                <div className='space-y-1'>
                  {pipelines
                    .filter(p => p.archived)
                    .map(pipeline => (
                      <NavLink
                        key={pipeline.id}
                        to={`/pipelines/${pipeline.id}`}
                        className={({ isActive }) => `
                        px-2 py-1.5 rounded-md transition-colors duration-200 w-full flex items-center space-x-2
                        ${
                          isActive
                            ? 'bg-primary-100 text-base-950'
                            : 'hover:bg-base-100 dark:hover:bg-base-700 text-base-700 dark:text-base-300'
                        }
                      `}
                      >
                        <span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.75rem] font-medium'>
                          {pipeline.title[0]}
                        </span>
                        <span className='hidden lg:block text-sm truncate'>
                          {pipeline.title}
                        </span>
                      </NavLink>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className='md:hidden'>
            <select
              className='w-full p-2 rounded-lg bg-base-100 dark:bg-base-700 text-base-700 dark:text-base-300 border border-base-300 dark:border-base-600'
              value={selectedPipeline ? selectedPipeline.id : ''}
              onChange={e => {
                const selected = pipelines.find(
                  p => p.id === parseInt(e.target.value)
                );
                if (selected) {
                  setSelectedPipeline(selected);
                  navigate(`/pipelines/${selected.id}`);
                }
              }}
            >
              <option value=''>Select a pipeline</option>
              <optgroup label='Global Pipelines'>
                {pipelines
                  .filter(p => p.isGlobal && !p.archived)
                  .map(pipeline => (
                    <option key={pipeline.id} value={pipeline.id}>
                      {pipeline.title}
                    </option>
                  ))}
              </optgroup>
              <optgroup label='My Pipelines'>
                {pipelines
                  .filter(p => !p.isGlobal && !p.archived)
                  .map(pipeline => (
                    <option key={pipeline.id} value={pipeline.id}>
                      {pipeline.title}
                    </option>
                  ))}
              </optgroup>
              <optgroup label='Archived'>
                {pipelines
                  .filter(p => p.archived)
                  .map(pipeline => (
                    <option key={pipeline.id} value={pipeline.id}>
                      {pipeline.title}
                    </option>
                  ))}
              </optgroup>
            </select>
          </div>
        </div>

        <div className='flex-1 w-full h-full p-4 overflow-hidden flex flex-col'>
          <div className='relative border-b border-gray-200 pb-5 sm:pb-0 mb-4'>
            <div className='flex items-center justify-between'>
              <h3 className='text-xl font-semibold leading-6 text-gray-900 dark:text-gray-100'>
                {selectedPipeline
                  ? selectedPipeline.title
                  : 'Select a Pipeline'}
                {selectedPipeline && (
                  <div className='flex items-center text-sm text-gray-400 dark:text-gray-300'>
                    <span className='text-xs font-base pr-2'>Created by</span>{' '}
                    {formatProfile(
                      {
                        firstName: selectedPipeline.owner.first_name,
                        lastName: selectedPipeline.owner.last_name,
                        fullName: selectedPipeline.owner.full_name,
                        profilePhoto: selectedPipeline.owner.profile_photo
                      },
                      'xs'
                    )}
                  </div>
                )}
              </h3>

              {selectedPipeline ? (
                <div className='flex mb-3'>
                  {currentUser.hasPermission(
                    EntityTypeEnum.Pipelines,
                    PERMISSIONS[EntityTypeEnum.Pipelines].EDIT
                  ) && (
                    <TextButton
                      className='mr-3'
                      onClick={() => setShowEditPopup(true)}
                      disabled={currentUser.id !== selectedPipeline.owner.id}
                      title={
                        currentUser.id !== selectedPipeline.owner.id
                          ? 'Only the pipeline owner can edit'
                          : ''
                      }
                    >
                      <Settings />
                    </TextButton>
                  )}
                  <FilledButton onClick={() => setShowAddItemPopup(true)}>
                    Add Item
                  </FilledButton>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='overflow-x-auto w-full flex-1'>{renderBoardView}</div>
        </div>

        {/* {showCreatePopup && (
          <CreatePipeline
            onClose={() => setShowCreatePopup(false)}
            onPipelineCreated={handlePipelineCreated}
          />
        )} */}
        {showBasicPopup && (
          <CreateBasicPipeline
            onClose={() => setShowBasicPopup(false)}
            onSave={newPipeline => handleBasicPipelineCreated(newPipeline)}
          />
        )}
        {showAddItemPopup && (
          <AddPipelineItem
            pipelineId={id}
            pipelineGroup={selectedGroupId ?? 1}
            onClose={() => setShowAddItemPopup(false)}
            onItemAdded={() => {
              fetchItems(selectedPipeline);
            }}
          />
        )}
        {showEditPopup && (
          <EditPipeline
            pipeline={selectedPipeline}
            onClose={() => setShowEditPopup(false)}
            onSave={editedPipeline => {
              handlePipelineEdit(editedPipeline);
              setShowEditPopup(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Pipelines;
