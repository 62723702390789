import BaseModel from './BaseModel';
import UserProfile from './UserProfile';

/**
 * Represents a Booking in the system.
 * @extends BaseModel
 */
class Booking extends BaseModel {
  /** @type {string} The name of the database table for Bookings */
  static table = 'bookings';

  /** @type {string} The SQL query to select Booking data with related information */
  static selectQuery = `
          *,
          companies!inner (name),
          trainers!inner (name),
          locations (name, address_1, address_2, city, county, postcode),
          courses!inner (name),
          delivery_type:core_entity_types!delivery_type (id, type),
          status:core_entity_types!status (id, type),
          sub_status:core_entity_types!sub_status (id, type),
          owner:user_profiles!inner (id, auth, first_name, last_name, email, profile_photo),
          booking_charges_count,
          booking_charges_sum,
          booking_spaces_fulfilled
        `;

  /**
   * Creates an instance of Booking.
   * @param {Object} data - The booking data.
   */
  constructor(data = {}) {
    super(data);
    this.quoteId = data.quote_id || null;
    this.company = data.company_id
      ? { id: data.company_id, name: data.companies?.name }
      : null;
    this.trainer = data.trainer_id
      ? { id: data.trainer_id, name: data.trainers?.name }
      : null;
    this.venue = data.venue_id
      ? {
          id: data.venue_id,
          name: data.locations?.name,
          address_1: data.locations?.address_1,
          address_2: data.locations?.address_2,
          city: data.locations?.city,
          county: data.locations?.county,
          postcode: data.locations?.postcode
        }
      : null;
    this.course = data.course_id
      ? { id: data.course_id, name: data.courses?.name }
      : null;
    this.deliveryType = data.delivery_type
      ? { id: data.delivery_type.id, name: data.delivery_type.type }
      : null;
    this.quotedDate = data.quoted_date || null;
    this.bookingDate = data.booking_date || null;
    this.startDate = data.start_date || null;
    this.endDate = data.end_date || null;
    this.paymentDate = data.payment_date || null;
    this.paidDate = data.paid_date || null;
    this.invoiceId = data.invoice_id || null;
    this.invoiceAmount = data.invoice_amount || null;
    this.tpoNumber = data.tpo_number || null;
    this.tpoAmount = data.tpo_amount || null;
    this.cpoNumber = data.cpo_number || null;
    this.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    this.subStatus = data.sub_status
      ? { id: data.sub_status.id, name: data.sub_status.type }
      : null;
    this.spaces = data.spaces || null;
    this.spaceRate = data.space_rate || null;
    this.vatRate = data.vat_rate || null;
    this.levy = data.levy || null;
    this.customReference = data.custom_reference || null;
    this.invoiceSent = data.invoice_sent || false;
    this.owner = data.owner
      ? {
          id: data.owner.id,
          auth: data.owner?.auth,
          firstName: data.owner?.first_name,
          lastName: data.owner?.last_name,
          fullName: `${data.owner?.first_name} ${data.owner?.last_name}`,
          email: data.owner?.email,
          profilePhoto: data.owner?.profile_photo
        }
      : null;
    this.followers = data.followers || [];
    this.contacts = data.contacts || [];
    this.metrics = {
      fulfilledSpaces: data.booking_spaces_fulfilled,
      charges: {
        count: data.booking_charges_count,
        total: data.booking_charges_sum
      }
    };
  }

  /**
   * Converts the Booking instance to a database-friendly format.
   * @returns {Object} The booking data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      quote_id: this.quoteId,
      company_id: this.company ? this.company.id : null,
      trainer_id: this.trainer ? this.trainer.id : null,
      venue_id: this.venue ? this.venue.id : null,
      course_id: this.course ? this.course.id : null,
      delivery_type: this.deliveryType ? this.deliveryType.id : null,
      quoted_date: this.quotedDate,
      booking_date: this.bookingDate,
      start_date: this.startDate,
      end_date: this.endDate,
      payment_date: this.paymentDate,
      paid_date: this.paidDate,
      invoice_id: this.invoiceId,
      invoice_amount: this.invoiceAmount,
      tpo_number: this.tpoNumber,
      tpo_amount: this.tpoAmount,
      cpo_number: this.cpoNumber,
      status: this.status ? this.status.id : null,
      sub_status: this.subStatus ? this.subStatus.id : null,
      spaces: this.spaces,
      space_rate: this.spaceRate,
      vat_rate: this.vatRate,
      levy: this.levy,
      custom_reference: this.customReference,
      invoice_sent: this.invoiceSent,
      owner: this.owner ? this.owner.id : null,
      followers: this.followers,
      contacts: this.contacts
    };
  }
  /**
   * Retrieves the user profiles for all contacts associated with this booking.
   * @returns {Promise<Array>} A promise that resolves to an array of UserModel instances.
   */
  async getContactProfiles() {
    if (!this.contacts || this.contacts.length === 0) {
      return [];
    }

    try {
      const { data: contactProfiles } = await UserProfile.getAll({
        id: {
          operator: 'in',
          value: this.contacts.map(contact => contact.user_id)
        }
      });
      return contactProfiles;
    } catch (error) {
      console.error('[Error] Fetching contact profiles:', error);
      throw error;
    }
  }
}

export default Booking;
