import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle
} from '@headlessui/react';
import { Check, AlertTriangle, XCircle } from 'lucide-react';

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  actions,
  style = 'primary'
}) => {
  const getStyleClasses = type => {
    const baseClasses =
      'inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2';
    const styleClasses = {
      brand:
        'bg-brand-700 text-white hover:bg-brand-600 focus-visible:outline-brand-700',
      primary:
        'bg-primary-700 text-white hover:bg-primary-600 focus-visible:outline-primary-700',
      success:
        'bg-success-600 text-white hover:bg-success-500 focus-visible:outline-success-600',
      warning:
        'bg-warning-600 text-white hover:bg-warning-500 focus-visible:outline-warning-600',
      danger:
        'bg-danger-600 text-white hover:bg-danger-500 focus-visible:outline-danger-600',
      info: 'bg-info-600 text-white hover:bg-info-500 focus-visible:outline-info-600'
    };
    return `${baseClasses} ${styleClasses[type]}`;
  };

  const getIcon = () => {
    switch (style) {
      case 'success':
        return (
          <Check className='h-6 w-6 text-success-600' aria-hidden='true' />
        );
      case 'warning':
        return (
          <AlertTriangle
            className='h-6 w-6 text-warning-600'
            aria-hidden='true'
          />
        );
      case 'danger':
        return (
          <XCircle className='h-6 w-6 text-danger-600' aria-hidden='true' />
        );
      default:
        return null;
    }
  };

  const getIconBackground = () => {
    switch (style) {
      case 'success':
        return 'bg-success-100';
      case 'warning':
        return 'bg-warning-100';
      case 'danger':
        return 'bg-danger-100';
      default:
        return '';
    }
  };

  const icon = getIcon();

  return (
    <Dialog open={isOpen} onClose={onClose} className='relative z-10'>
      <DialogBackdrop
        transition
        className='fixed inset-0 bg-base-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in'
      />

      <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <DialogPanel
            transition
            className='relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95'
          >
            <div>
              {icon && (
                <div
                  className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${getIconBackground()}`}
                >
                  {icon}
                </div>
              )}
              <div className='mt-3 text-center sm:mt-5'>
                <DialogTitle
                  as='h3'
                  className='text-base font-semibold leading-6 text-primary-900'
                >
                  {title}
                </DialogTitle>
                <div className='mt-2'>{children}</div>
              </div>
            </div>
            {actions && (
              <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                {actions.map((action, index) => (
                  <button
                    key={index}
                    type='button'
                    onClick={action.onClick}
                    className={`${getStyleClasses(action.style || style)} ${
                      index === 0 ? 'sm:col-start-2' : 'sm:col-start-1 sm:mt-0'
                    } ${index !== 0 ? 'mt-3' : ''}`}
                  >
                    {action.label}
                  </button>
                ))}
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export { Modal };
