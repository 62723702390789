import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BookingSpace from '../../../../models/BookingSpace';
import Booking from '../../../../models/Booking';
import EntityTable from '../../../../components/tables/EntityTable';
import { formatDate } from '../../../../utilities/Formatting';
import {
  Pencil,
  SquareArrowOutUpRight,
  Trash,
  AlertTriangle,
  Pen,
  User
} from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import CoreEntityTypes from '../../../../models/CoreEntityType';
import { Alert, TextButton } from 'core';
import AssignUser from '../../../../components/popups/booking-space/AssignUser';

const BookingSpaces = () => {
  const { id: bookingId } = useParams();
  const [statusOptions, setStatusOptions] = useState([]);
  const [levyStatusOptions, setLevyStatusOptions] = useState([]);
  const [booking, setBooking] = useState(null);
  const [spacesCount, setSpacesCount] = useState(0);
  const [isAssignUserOpen, setIsAssignUserOpen] = useState(false);
  const [selectedBookingSpaceId, setSelectedBookingSpaceId] = useState(null);
  const [selectedBookingSpace, setSelectedBookingSpace] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statusTypes, levyStatusTypes, fetchedBooking] =
          await Promise.all([
            CoreEntityTypes.getByEntityId(EntityTypeEnum.BookingSpaceStatus),
            CoreEntityTypes.getByEntityId(EntityTypeEnum.BookingSpaceLevyType),
            Booking.getById(bookingId)
          ]);

        setStatusOptions(
          statusTypes.map(option => ({
            key: option.id,
            value: option.type
          }))
        );

        setLevyStatusOptions(
          levyStatusTypes.map(option => ({
            key: option.id,
            value: option.type
          }))
        );

        setBooking(fetchedBooking);

        const spaces = await BookingSpace.getAll({
          booking_id: { operator: FilterOperatorEnum.EQUALS, value: bookingId }
        });
        setSpacesCount(spaces.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [bookingId]);

  const handleAssignUser = async bookingSpaceId => {
    try {
      const bookingSpace = await BookingSpace.getById(bookingSpaceId);
      setSelectedBookingSpace(bookingSpace);
      setSelectedBookingSpaceId(bookingSpaceId);
      setIsAssignUserOpen(true);
    } catch (error) {
      console.error('Error fetching booking space:', error);
    }
  };

  const menuItems = [
    {
      label: 'Edit Delegate',
      icon: <User size={14} />,
      onClick: data => handleAssignUser(data.id)
    },
    {
      label: 'Edit Space',
      icon: <Pencil size={14} />
      // onclick: data => ()
    }
  ];

  const handleRowClick = row => {
    handleAssignUser(row.id);
  };

  return (
    <>
      {booking && spacesCount > booking.numberOfSpaces && (
        <Alert
          type='danger'
          icon={<AlertTriangle size={16} />}
          title='Sufficient Booking Spaces'
          description={`This booking has ${spacesCount} spaces allocated, which exceeds the required ${booking.numberOfSpaces} spaces.`}
        />
      )}
      <EntityTable
        name='Booking Spaces'
        filter={[
          { field: 'id', label: 'ID', type: 'number' },
          {
            field: 'delegate.full_name',
            label: 'Delegate Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions
          },
          {
            field: 'levy_status',
            label: 'Levy Status',
            type: 'select',
            options: levyStatusOptions
          },
          { field: 'created_date', label: 'Created Date', type: 'date' },
          { field: 'completion_date', label: 'Completion Date', type: 'date' },
          { field: 'expiry_date', label: 'Expiry Date', type: 'date' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id'
          },
          {
            label: 'Delegate',
            field: 'delegate',
            foreignKey: { table: 'delegate', column: 'full_name' },
            cell: value => {
              if (!value) return '';
              return (
                <TextButton colour='brand' to={`/users/${value.id}`}>
                  {`${value.firstName} ${value.lastName}`}
                </TextButton>
              );
            }
          },
          {
            label: 'Status',
            field: 'status.name',
            foreignKey: { table: 'status', column: 'type' }
          },
          {
            label: 'Levy Status',
            field: 'levyStatus.name',
            foreignKey: { table: 'levy_status', column: 'type' }
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Completion Date',
            field: 'completionDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Expiry Date',
            field: 'expiryDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={BookingSpace}
        entityType={EntityTypeEnum.BookingSpace}
        defaultFilters={{ booking_id: { value: bookingId } }}
        onRowClick={handleRowClick}
      />
      {isAssignUserOpen && (
        <AssignUser
          bookingSpaceId={selectedBookingSpaceId}
          onClose={() => setIsAssignUserOpen(false)}
          onAssign={() => {
            // Handle successful assignment
            setIsAssignUserOpen(false);
            // You might want to refresh the table data here
          }}
        />
      )}
    </>
  );
};

export default BookingSpaces;
