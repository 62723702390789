import React, { useState } from 'react';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import Location from '../../../../models/Location';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
//import AddOffice from '../../../../components/popups/companies/AddOffice';

const Offices = ({ companyId }) => {
  const [isAddOfficeOpen, setIsAddOfficeOpen] = useState(false);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/office/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/office/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleNewClick = () => {
    setIsAddOfficeOpen(true);
  };

  const handleCloseAddOffice = () => {
    setIsAddOfficeOpen(false);
  };

  const handleOfficeAdded = newOffice => {
    console.log('New office added:', newOffice);
  };

  return (
    <>
      <EntityTable
        name='Offices'
        filter={[
          { field: 'id', label: 'ID', type: 'number' },
          {
            field: 'name',
            label: 'Name',
            type: 'text',
            operator: FilterOperatorEnum.TEXTSEARCH
          },
          {
            field: 'address',
            label: 'Address',
            type: 'text',
            operator: FilterOperatorEnum.TEXTSEARCH
          },
          {
            field: 'phone',
            label: 'Phone',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'email',
            label: 'Email',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          }
        ]}
        columns={[
          { label: 'ID', field: 'id' },
          { label: 'Name', field: 'name' },
          { label: 'Address', field: 'fullAddress' },
          { label: 'Status', field: 'status.type' }
        ]}
        menuItems={menuItems}
        model={Location}
        entityType={EntityTypeEnum.Location}
        defaultFilters={{
          entity_type: { value: EntityTypeEnum.Company },
          entity_id: { value: companyId }
        }}
        onNewClick={handleNewClick}
        canCreate={true}
      />
      {/* <AddOffice
        isOpen={isAddOfficeOpen}
        onClose={handleCloseAddOffice}
        companyId={companyId}
        onOfficeAdded={handleOfficeAdded}
      /> */}
    </>
  );
};

export default Offices;
