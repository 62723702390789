import React, { useEffect, useRef, useState } from 'react';

const Map = ({
  address,
  latitude = 51.5074, // Default latitude for London
  longitude = -0.1278, // Default longitude for London
  zoom = 16,
  width = '100%',
  height = '200px',
  apiKey
}) => {
  const mapRef = useRef(null);
  const googleMapRef = useRef(null);
  const markerRef = useRef(null);
  const [coordinates, setCoordinates] = useState({
    lat: latitude,
    lng: longitude
  });

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      return script;
    };

    window.initMap = () => {
      googleMapRef.current = new window.google.maps.Map(mapRef.current, {
        center: coordinates,
        zoom: zoom
      });

      markerRef.current = new window.google.maps.Marker({
        position: coordinates,
        map: googleMapRef.current
      });

      // Geocoding function
      if (address) {
        console.log(address);
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === 'OK' && results[0]) {
            const newCoordinates = results[0].geometry.location.toJSON();
            setCoordinates(newCoordinates);
            googleMapRef.current.setCenter(newCoordinates);
            markerRef.current.setPosition(newCoordinates);
          }
        });
      }
    };

    const script = loadGoogleMapsScript();

    return () => {
      document.head.removeChild(script);
      delete window.initMap;
    };
  }, [address, zoom, apiKey]);

  useEffect(() => {
    if (googleMapRef.current && markerRef.current) {
      markerRef.current.setPosition(coordinates);
      googleMapRef.current.setCenter(coordinates);
    }
  }, [coordinates]);

  return (
    <div className='relative' style={{ width, height }}>
      <div
        ref={mapRef}
        style={{ width: '100%', height: '100%' }}
        className='rounded-lg shadow-md'
      />
    </div>
  );
};

export { Map };
