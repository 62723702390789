import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import Booking from '../../../models/Booking';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import Company from '../../../models/Company';
import UserProfile from '../../../models/UserProfile';
import { FilledButton, SoftButton } from 'core';
import CompanyStage from './create/CompanyStage';
import CourseStage from './create/CourseStage';
import TrainerStage from './create/TrainerStage';
import DetailsStage from './create/DetailsStage';
import AdditionalCharge from '../../../models/AdditionalCharge';
import { UserProfileContext } from '../../../App';
import Companies from '../companies/Companies';

const CreateBooking = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  const [currentStep, setCurrentStep] = useState(0);
  const [bookingData, setBookingData] = useState({
    company: null,
    course: null,
    trainer: null,
    venue: null,
    deliveryType: null,
    startDate: null,
    endDate: null,
    spaces: '',
    spaceRate: '',
    location: '',
    levyRequired: false,
    primaryBookers: [],
    selectedLevy: null,
    paymentDueDate: null,
    tradeXCompany: null,
    additionalCharges: [],
    autoCreditControl: false
  });
  const [options, setOptions] = useState({
    chargeStatuses: [],
    chargeTypes: [],
    company: [],
    course: [],
    trainer: [],
    deliveryType: [],
    levy: []
  });
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const { data: deliveryTypes } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.CourseDelivery }
        });

        const { data: chargeTypes } = await CoreEntityType.getAll({
          entity_id: {
            operator: 'eq',
            value: EntityTypeEnum.AdditionalChargeType
          }
        });

        const { data: chargeStatuses } = await CoreEntityType.getAll({
          entity_id: {
            operator: 'eq',
            value: EntityTypeEnum.AdditionalChargeStatus
          }
        });

        setOptions(prevOptions => ({
          ...prevOptions,
          chargeTypes: chargeTypes.map(type => ({
            value: type.id,
            label: type.type
          })),
          chargeStatuses: chargeStatuses.map(status => ({
            value: status.id,
            label: status.type
          })),
          deliveryType: deliveryTypes.map(type => ({
            value: type.id,
            label: type.type
          }))
        }));
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const updateLevyOptions = useCallback(async () => {
    if (bookingData.company && bookingData.primaryBookers.length > 0) {
      try {
        const companyData = await Company.getById(bookingData.company.value);
        setCompanyData(companyData);
        const bookerLevies = await Promise.all(
          bookingData.primaryBookers.map(async booker => {
            const userData = await UserProfile.getById(booker.value);
            return { levy: userData.levy, name: userData.fullName };
          })
        );

        const levyOptions = [
          ...(companyData.levy
            ? [{ levy: companyData.levy, source: 'Company' }]
            : []),
          ...bookerLevies
            .filter(({ levy }) => levy !== null)
            .map(({ levy, name }) => ({ levy, source: `Booker: ${name}` }))
        ];

        if (bookingData.levyRequired) bookingData.selectedLevy = levyOptions[0];

        setOptions(prevOptions => ({
          ...prevOptions,
          levy: levyOptions.map(({ levy, source }) => ({
            value: levy,
            label: `${levy} (${source})`
          }))
        }));
      } catch (error) {
        console.error('Error fetching levy options:', error);
      }
    }
  }, [bookingData.company, bookingData.primaryBookers]);

  useEffect(() => {
    updateLevyOptions();
  }, [updateLevyOptions]);

  const handleInputChange = useCallback(
    (field, value) => {
      setBookingData(prev => ({ ...prev, [field]: value }));
      if (field === 'company') {
        updateLevyOptions();
      }
    },
    [updateLevyOptions]
  );

  const handleSubmit = async () => {
    try {
      // Prepare contacts array for the booking
      const contacts = bookingData.primaryBookers.map(booker => ({
        user_id: booker.value,
        contact_type: 'Booker'
      }));

      // Add contacts to the booking data
      const newBooking = new Booking({
        company_id: bookingData.company?.value,
        course_id: bookingData.course?.value,
        trainer_id: bookingData.trainer?.value,
        venue_id: bookingData.venue?.value,
        delivery_type: { id: bookingData.deliveryType },
        start_date: bookingData.startDate,
        end_date: bookingData.endDate,
        payment_date: bookingData.paymentDueDate,
        spaces: parseInt(bookingData.spaces),
        space_rate: parseFloat(bookingData.spaceRate),
        vat_rate: parseFloat(bookingData.vatRate ?? 20.0),
        levy: bookingData.selectedLevy?.value,
        owner: userProfile,
        status: { id: 141 },
        sub_status: { id: 146 },
        //custom_reference: bookingData.customReference,
        booking_date: new Date().toISOString(),
        contacts: contacts
      });

      const insertedBooking = await newBooking.insert();

      const company = await Company.getById(insertedBooking.company.id);
      company.lastBooked = new Date();
      company.update();

      // Insert additional charges
      if (bookingData.additionalCharges.length > 0) {
        const chargePromises = bookingData.additionalCharges.map(charge =>
          new AdditionalCharge({
            booking_id: insertedBooking.id,
            description: charge.description,
            charge_type: { id: charge.type },
            amount: parseFloat(charge.amount),
            vat_rate: parseFloat(charge.vatRate),
            status: { id: charge.status }
          }).insert()
        );
        await Promise.all(chargePromises);
      }

      navigate(`/bookings/${insertedBooking.id}`);
    } catch (error) {
      console.error('Error creating booking:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleAdditionalChargeChange = useCallback((index, field, value) => {
    setBookingData(prev => {
      const updatedCharges = [...prev.additionalCharges];
      updatedCharges[index][field] = value;
      return { ...prev, additionalCharges: updatedCharges };
    });
  }, []);

  const addAdditionalCharge = useCallback(() => {
    setBookingData(prev => ({
      ...prev,
      additionalCharges: [
        ...prev.additionalCharges,
        { description: '', amount: '' }
      ]
    }));
  }, []);

  const removeAdditionalCharge = useCallback(index => {
    setBookingData(prev => ({
      ...prev,
      additionalCharges: prev.additionalCharges.filter((_, i) => i !== index)
    }));
  }, []);

  const steps = [
    {
      title: 'Company Details',
      render: () => (
        <CompanyStage
          bookingData={bookingData}
          handleInputChange={handleInputChange}
          options={options}
        />
      )
    },
    {
      title: 'Course Details',
      render: () => (
        <CourseStage
          bookingData={bookingData}
          handleInputChange={handleInputChange}
          options={options}
        />
      )
    },
    {
      title: 'Trainer Details',
      render: () => (
        <TrainerStage
          bookingData={bookingData}
          handleInputChange={handleInputChange}
          options={options}
        />
      )
    },
    {
      title: 'Booking Details',
      render: () => (
        <DetailsStage
          bookingData={bookingData}
          handleInputChange={handleInputChange}
          handleAdditionalChargeChange={handleAdditionalChargeChange}
          removeAdditionalCharge={removeAdditionalCharge}
          addAdditionalCharge={addAdditionalCharge}
          options={options}
          companyData={companyData}
        />
      )
    }
  ];

  return (
    <div className='bg-base-100 h-full'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={() => navigate('/bookings')}
          className='mb-8'
        >
          Back to Bookings
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Create New Booking</h2>
          </div>

          <div className='p-6'>
            <div className='mb-8'>
              <div className='flex justify-between'>
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${
                      index <= currentStep ? 'text-brand-700' : 'text-base-400'
                    }`}
                  >
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        index <= currentStep
                          ? 'bg-brand-700 text-white'
                          : 'bg-base-200'
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span className='ml-2 text-sm font-medium'>
                      {step.title}
                    </span>
                  </div>
                ))}
              </div>
              <div className='mt-4 h-2 bg-base-200 rounded-full'>
                <div
                  className='h-full bg-brand-700 rounded-full transition-all duration-300 ease-in-out'
                  style={{
                    width: `${((currentStep + 1) / steps.length) * 100}%`
                  }}
                ></div>
              </div>
            </div>

            {steps[currentStep].render()}

            <div className='mt-8 flex justify-between'>
              <SoftButton
                colour='primary'
                size='lg'
                leftIcon={<ArrowLeft size={18} />}
                onClick={() => setCurrentStep(prev => Math.max(0, prev - 1))}
                disabled={currentStep === 0}
              >
                Previous
              </SoftButton>
              {currentStep < steps.length - 1 ? (
                <FilledButton
                  colour='primary'
                  size='lg'
                  rightIcon={<ArrowRight size={18} />}
                  onClick={() =>
                    setCurrentStep(prev => Math.min(steps.length - 1, prev + 1))
                  }
                >
                  Next
                </FilledButton>
              ) : (
                <FilledButton colour='primary' size='lg' onClick={handleSubmit}>
                  Create Booking
                </FilledButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBooking;
