import React, { useState, useEffect } from 'react';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import BookingSpace from '../../../../models/BookingSpace';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { formatDate } from '../../../../utilities/Formatting';
import CoreEntityType from '../../../../models/CoreEntityType';

const Tracker = ({ companyId }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [missingSpaces, setMissingSpaces] = useState(0);

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: {
            operator: 'eq',
            value: EntityTypeEnum.BookingSpaceStatus
          }
        });
        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    const countMissingDelegates = async () => {
      const { count: spaces } = await BookingSpace.getAll(
        {
          'booking.company_id': { value: companyId },
          delegate_id: { operator: 'is', value: 'null' }
        },
        1,
        10,
        '',
        'asc',
        false
      );

      setMissingSpaces(spaces);
    };

    fetchStatusOptions();
    countMissingDelegates();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/booking-space/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/booking-space/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const columns = [
    { label: 'ID', field: 'id' },
    { label: 'Booking', field: 'booking.id' },
    { label: 'Delegate', field: 'delegate.fullName' },
    {
      label: 'Completion Date',
      field: 'completionDate',
      cell: value => formatDate(value)
    },
    {
      label: 'Expiry Date',
      field: 'expiryDate',
      cell: value => formatDate(value)
    },
    { label: 'Status', field: 'status.name' }
  ];

  const filters = [
    { field: 'id', label: 'ID', type: 'number' },
    {
      field: 'booking.start_date',
      label: 'Start Date',
      type: 'date',
      operator: FilterOperatorEnum.GREATER_THAN_OR_EQUAL
    },
    {
      field: 'booking.end_date',
      label: 'End Date',
      type: 'date',
      operator: FilterOperatorEnum.LESS_THAN_OR_EQUAL
    },
    {
      field: 'status',
      label: 'Status',
      type: 'select',
      options: statusOptions,
      operator: FilterOperatorEnum.EQUALS
    }
  ];

  return (
    <div className='space-y-8'>
      {missingSpaces > 0 && (
        <div className='p-2 rounded-md bg-danger-50'>
          <h2 className='text-xl font-bold mb-4'>Unfilled Spaces</h2>
          <EntityTable
            name='Unfilled Spaces'
            filter={filters}
            columns={columns}
            menuItems={menuItems}
            model={BookingSpace}
            entityType={EntityTypeEnum.BookingSpace}
            flags={{ includeDelegate: false }}
            defaultFilters={{
              'booking.company_id': { value: companyId },
              delegate_id: { operator: 'is', value: 'null' }
            }}
          />
        </div>
      )}

      <div>
        <EntityTable
          name='All Spaces'
          filter={filters}
          columns={columns}
          menuItems={menuItems}
          model={BookingSpace}
          entityType={EntityTypeEnum.BookingSpace}
          defaultFilters={{
            'booking.company_id': { value: companyId }
          }}
        />
      </div>
    </div>
  );
};

export default Tracker;
