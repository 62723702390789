import BaseModel from './BaseModel';

/**
 * Represents an Additional Charge in the system.
 * @extends BaseModel
 */
class AdditionalCharge extends BaseModel {
  /** @type {string} The name of the database table for Additional Charges */
  static table = 'additional_charges';

  /** @type {string} The SQL query to select Additional Charge data with related information */
  static selectQuery = `
    *,
    charge_type:core_entity_types!charge_type (id, type),
    status:core_entity_types!status (id, type),
    owner:user_profiles (id, auth, first_name, last_name, email, profile_photo)
  `;

  /**
   * Creates an instance of AdditionalCharge.
   * @param {Object} data - The additional charge data.
   */
  constructor(data = {}) {
    super(data);
    this.booking = data.booking || null;
    this.invoiceId = data.invoice_id || null;
    this.owner = data.owner
      ? {
          id: data.owner.id,
          auth: data.owner?.auth,
          firstName: data.owner?.first_name,
          lastName: data.owner?.last_name,
          fullName: `${data.owner?.first_name} ${data.owner?.last_name}`,
          email: data.owner?.email,
          profilePhoto: data.owner?.profile_photo
        }
      : null;
    this.description = data.description || null;
    this.chargeType = data.charge_type
      ? { id: data.charge_type.id, name: data.charge_type.type }
      : null;
    this.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    this.vatRate = data.vat_rate || null;
    this.amount = data.amount || null;
  }

  /**
   * Converts the AdditionalCharge instance to a database-friendly format.
   * @returns {Object} The additional charge data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      booking: this.booking,
      invoice_id: this.invoiceId,
      owner: this.owner ? this.owner.id : null,
      description: this.description,
      charge_type: this.chargeType ? this.chargeType.id : null,
      status: this.status ? this.status.id : null,
      vat_rate: this.vatRate,
      amount: this.amount
    };
  }
}

export default AdditionalCharge;
