import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../../components/navigation/Header';
import Sidebar from '../../components/navigation/Sidebar';
import {
  Home as HomeIcon,
  ListTodo,
  Users as UsersIcon,
  Building,
  GraduationCap,
  FileSpreadsheet,
  CalendarCheck,
  TicketCheck,
  Presentation,
  ReceiptText,
  Folders,
  SquareKanban
} from 'lucide-react';
import Home from './home/Home';
import Bookings from './bookings/Bookings';
import Users from './users/Users';
import UserDetails from './users/User';
import Courses from './courses/Courses';
import Companies from './companies/Companies';
import Company from './companies/Company';
import Trainer from './trainers/Trainers';
import Documents from './documents/Documents';
import BookingSpaces from './booking-spaces/BookingSpaces';
import Tasks from './tasks/Tasks';
import Quotes from './quotes/Quotes';
import BookingPage from './bookings/Booking';
import CreateBooking from './bookings/CreateBooking';
import Pipelines from './pipelines/Pipelines';
//import Invoices from './invoices/Invoices';

const Dashboard = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Load dark mode preference from local storage
    const userPreferences =
      JSON.parse(localStorage.getItem('user_preferences')) || {};
    setIsDarkMode(userPreferences.darkMode || false);
  }, []);

  const sidebarItems = [
    { icon: SquareKanban, name: 'Pipelines', path: '/pipelines' },
    { icon: ListTodo, name: 'Tasks', path: '/tasks' },
    { icon: FileSpreadsheet, name: 'Quotes', path: '/quotes' },
    { icon: CalendarCheck, name: 'Bookings', path: '/bookings' },
    { icon: TicketCheck, name: 'Booking Spaces', path: '/booking-spaces' },
    { icon: UsersIcon, name: 'Users', path: '/user-profiles' },
    { icon: Building, name: 'Companies', path: '/companies' },
    { icon: GraduationCap, name: 'Courses', path: '/courses' },
    { icon: Presentation, name: 'Trainers', path: '/trainers' },
    { icon: ReceiptText, name: 'Invoices', path: '/invoices' },
    { icon: Folders, name: 'Documents', path: '/documents' }
  ];

  return (
    <div
      className={`flex flex-col md:flex-row h-screen w-screen ${
        isDarkMode ? 'dark' : ''
      }`}
    >
      <div className='hidden lg:block'>
        <Sidebar items={sidebarItems} />
      </div>
      <div className='flex flex-col flex-1 bg-white dark:bg-neutral-900 w-full overflow-hidden'>
        <Header
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
          sidebarItems={sidebarItems}
        />
        <main className='flex-auto text-neutral-600 dark:text-neutral-300'>
          <Routes>
            <Route path='/*' element={<Home />} />
            <Route
              path='/quotes'
              element={<Quotes/>}
            />
            <Route
              path='/bookings'
              element={<Bookings />}
            />
            <Route
              path='/bookings/:id/*'
              element={<BookingPage />}
            />
            <Route
              path='/bookings/create'
              element={<CreateBooking/>}
            />
            <Route
              path='/booking-spaces'
              element={<BookingSpaces />}
            />
            <Route
              path='/user-profiles'
              element={<Users />}
            />
            <Route
              path='/users/:id/*'
              element={<UserDetails />}
            />
            <Route
              path='/courses'
              element={<Courses />}
            />
            <Route
              path='/companies'
              element={<Companies />}
            />
            <Route
              path='/companies/:id/*'
              element={<Company />}
            />
            <Route
              path='/trainers'
              element={<Trainer />}
            />
            <Route
              path='/documents'
              element={<Documents />}
            />
            <Route
              path='/tasks'
              element={<Tasks />}
            />
            <Route path='/pipelines' element={<Pipelines />} />
            <Route path='/pipelines/:id' element={<Pipelines />} />
            {/* <Route path='/invoices' element={<Invoices />} /> */}
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
