import React from 'react';
import { Database, AlertCircle, ChevronUp, ChevronDown } from 'lucide-react';
import { ContextMenu, useContextMenu, TextSkeleton } from 'core';

const Table = ({
  columns,
  data,
  isLoading,
  isError,
  menuItems,
  sortBy,
  sortOrder,
  onSort,
  onRowClick
}) => {
  const { contextMenu, handleContextMenu, closeContextMenu } = useContextMenu();

  const handleRowClick = row => {
    if (onRowClick) {
      onRowClick(row);
    } else if (menuItems && menuItems.length > 0) {
      menuItems[1].onClick(row);
    }
  };

  const handleHeaderClick = column => {
    if (column.sortable) {
      onSort(column.field);
    }
  };

  return (
    <div className={`overflow-x-auto bg-white rounded-md overflow-hidden`}>
      <table className='min-w-full divide-y divide-neutral-200'>
        <thead className=''>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className={`py-3 px-3 text-left text-sm font-semibold text-neutral-700 ${
                  column.sortable ? 'cursor-pointer' : ''
                }`}
                onClick={() => handleHeaderClick(column)}
              >
                <div className='flex items-center'>
                  {column.label}
                  {column.sortable && sortBy === column.sortField && (
                    <span className='ml-1'>
                      {sortOrder === 'asc' ? (
                        <ChevronUp size={16} />
                      ) : (
                        <ChevronDown size={16} />
                      )}
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='divide-y divide-neutral-50'>
          {isLoading ? (
            <>
              {[...Array(3)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((column, cellIndex) => (
                    <td key={cellIndex} className='py-2 px-3'>
                      <TextSkeleton />
                    </td>
                  ))}
                </tr>
              ))}
            </>
          ) : isError ? (
            <tr>
              <td
                colSpan={columns.length}
                className='py-8 text-center text-neutral-600'
              >
                <div className='flex flex-col items-center'>
                  <AlertCircle size={38} className='text-neutral-300 mb-2' />
                  <p className='text-neutral-300 font-medium'>
                    There was an error loading the data
                  </p>
                </div>
              </td>
            </tr>
          ) : data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                onClick={() => handleRowClick(row)}
                onContextMenu={e => handleContextMenu(e, row)}
                className='hover:bg-neutral-50 cursor-pointer'
              >
                {columns.map((column, cellIndex) => (
                  <td
                    key={cellIndex}
                    className='py-3 px-3 whitespace-nowrap text-sm font-regular text-neutral-700'
                  >
                    {column.cell
                      ? column.cell(row[column.field], row)
                      : column.field
                          .split('.')
                          .reduce((obj, key) => obj && obj[key], row)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length}
                className='py-8 text-center text-neutral-600'
              >
                <div className='flex flex-col items-center'>
                  <Database size={38} className='text-neutral-300 mb-2' />
                  <p className='text-neutral-300 font-medium'>No data found</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {contextMenu && menuItems && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={closeContextMenu}
          menuItems={menuItems.map(item => ({
            ...item,
            onClick: () => item.onClick(contextMenu.data),
            visible: item.condition ? item.condition(contextMenu.data) : true
          }))}
        />
      )}
    </div>
  );
};

export default Table;
