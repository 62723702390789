import { useState, useEffect, createContext } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from 'react-router-dom';
import { supabase } from './utilities/supabase';
import { LogoLoader } from 'core';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/auth/login/Login';
import Signup from './pages/auth/signup/Signup';
import ForgotPassword from './pages/auth/forgotPassword/ForgotPassword';
import Account from './pages/account/Account';
import ResetPassword from './pages/auth/resetPassword/ResetPassword';
import Loading from './pages/auth/loading/Loading';
import StylePages from './pages/styles/Styles';
import Error from './pages/error/Error';
import UserProfile from './models/UserProfile';
import NotificationProvider from '../core/src/components/providers/NotificationProvider';
import MaintenancePage from './pages/error/Maintenance';

import * as Sentry from '@sentry/react';
//We only want to initialise sentry for the production app so we will cut out localhost from sending events or telemetry
if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://eab10ccf5d84cece6c85b58d4bc74094@o4508064831766528.ingest.de.sentry.io/4508064838451280',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://portal.bohsa.co.uk'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const UserProfileContext = createContext(null);
export const ThemeContext = createContext(null);

const App = () => {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [theme, setTheme] = useState('light');

  const [isBackendAvailable, setIsBackendAvailable] = useState(true);

  const checkBackendServices = async () => {
    try {
      // Check Supabase connection
      const { error } = await supabase
        .from('core_entities')
        .select('id')
        .limit(1);
      if (error) throw error;

      // Add more checks here if needed (e.g., API endpoints)

      setIsBackendAvailable(true);
    } catch (error) {
      console.error('Backend services are unavailable:', error);
      setIsBackendAvailable(false);
    }
  };

  useEffect(() => {
    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        setSession(session);
        UserProfile.getByAuthId(session.user.id).then(({ data, error }) => {
          console.log('data', data);
          if (data) {
            const activeProfile = data.find(profile => profile.active) || data[0];
            setUserProfile(activeProfile);
          } else if (error) {
            console.error('Error fetching user profile:', error);
          }
        });
        console.log('SIGNED IN');
      } else if (event === 'SIGNED_OUT') {
        setSession(null);
        setUserProfile(null);
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      await checkBackendServices();
      if (!isBackendAvailable) return;

      const {
        data: { session }
      } = await supabase.auth.getSession();
      setSession(session);

      if (session) {
        //await fetchUserProfile(session.user.id);
        // Get user preferences and set theme
        const userPreferences =
          JSON.parse(localStorage.getItem('user_preferences')) || {};
        setTheme(userPreferences.theme || 'light');
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };

    checkSession();
  }, []);

  if (isLoading) return <LogoLoader logo={'/logo-star.png'} />;
  if (showLoadingScreen) return <Loading />;
  if (!isBackendAvailable) return <MaintenancePage />;

  const router = createBrowserRouter([
    {
      path: '/',
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: session ? <Dashboard /> : <Navigate to='/login' />
        },
        {
          path: 'login',
          element: !session ? <Login /> : <Navigate to='/' />
        },
        {
          path: 'password',
          element: !session ? <ForgotPassword /> : <Navigate to='/' />
        },
        {
          path: 'resetPassword',
          element: !session ? <ResetPassword /> : <Navigate to='/' />
        },
        {
          path: 'signup',
          element: !session ? <Signup /> : <Navigate to='/' />
        },
        {
          path: 'account',
          element: session ? <Account /> : <Navigate to='/' />
        },
        {
          path: 'styles',
          element: session ? <StylePages /> : <Navigate to='/' />
        },
        {
          path: '*',
          element: session ? <Dashboard /> : <Navigate to='/login' />
        }
      ]
    }
  ]);

  return (
    <UserProfileContext.Provider value={{ userProfile, setUserProfile }}>
      <NotificationProvider>
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <RouterProvider router={router} />
        </ThemeContext.Provider>
      </NotificationProvider>
    </UserProfileContext.Provider>
  );
};

export default App;
