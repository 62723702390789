import React from 'react';
import { DatePicker, Toggle, Input, SoftButton, DropdownInput } from 'core';

const DetailsStage = ({
  bookingData,
  handleInputChange,
  handleAdditionalChargeChange,
  removeAdditionalCharge,
  addAdditionalCharge,
  options,
  companyData
}) => {
  return (
    <div className='space-y-6'>
      <DatePicker
        label='Payment Due Date'
        placeholder='Select payment due date'
        onChange={selectedDate =>
          handleInputChange('paymentDueDate', selectedDate)
        }
        value={bookingData.paymentDueDate}
        className='w-full'
        colour='primary'
        required
      />
      <Toggle
        label='TradeX Company'
        checked={companyData.tradex}
        className='w-full'
        description={`TradeX task for ${companyData.name} will be due in ${companyData.tradexOption ?? 0} hours.`}
        disabled
      />
      <div>
        <h3 className='text-sm text-primary-900 font-semibold mb-2'>
          Additional Charges
        </h3>
        <div className='overflow-x-auto bg-white rounded-md overflow-hidden'>
          <table className='min-w-full divide-y divide-neutral-200'>
            <thead>
              <tr>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Description
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Type
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Amount
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Vat Rate
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Status
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {bookingData.additionalCharges.map((charge, index) => (
                <tr key={index}>
                  <td className='border p-2'>
                    <Input
                      value={charge.description}
                      onChange={e =>
                        handleAdditionalChargeChange(
                          index,
                          'description',
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td className='border p-2'>
                    <DropdownInput
                      options={options.chargeTypes}
                      value={charge.type}
                      onChange={e =>
                        handleAdditionalChargeChange(
                          index,
                          'type',
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td className='border p-2'>
                    <Input
                      type='number'
                      value={charge.amount}
                      onChange={e =>
                        handleAdditionalChargeChange(
                          index,
                          'amount',
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td className='border p-2'>
                    <Input
                      type='number'
                      value={charge.vatRate}
                      onChange={e =>
                        handleAdditionalChargeChange(
                          index,
                          'vatRate',
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td className='border p-2'>
                    <DropdownInput
                      options={options.chargeStatuses}
                      value={charge.status}
                      onChange={e =>
                        handleAdditionalChargeChange(
                          index,
                          'status',
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td className='border p-2'>
                    <SoftButton
                      onClick={() => removeAdditionalCharge(index)}
                      colour='danger'
                    >
                      Remove
                    </SoftButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <SoftButton
          onClick={addAdditionalCharge}
          colour='primary'
          className='mt-2'
        >
          Add Charge
        </SoftButton>
      </div>
    </div>
  );
};

export default DetailsStage;
