import { useState } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';

const Sidebar = ({ items }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  // const navigate = useNavigate();

  const renderMenuItem = item => (
    <NavLink
      key={item.name}
      to={item.path}
      className={({ isActive }) => `
        flex items-center p-2 rounded transition-colors duration-300 mb-2 w-full
        ${
          isActive ? 'bg-brand-800 text-white' : 'hover:bg-brand-800 text-white'
        }
      `}
    >
      <div className='w-6 h-6 flex items-center justify-center'>
        <item.icon size={20} className='flex-shrink-0 text-white' />
      </div>
      {isExpanded && (
        <span className='ml-4 mr-2 whitespace-nowrap overflow-hidden transition-all duration-300 ease-in-out max-w-[200px]'>
          {item.name}
        </span>
      )}
    </NavLink>
  );

  return (
    <div
      id='sideNav'
      className={`bg-brand-700 shadow-md h-screen ${
        isExpanded ? 'w-52' : 'w-14'
      } transition-all duration-300 ease-in-out flex flex-col`}
    >
      <div
        className={`flex h-12 shrink-0 items-center p-2 ${
          !isExpanded ? 'justify-center' : ''
        }`}
      >
        <NavLink
          to='/'
          className='flex items-center justify-center h-full w-full'
        >
          <div className='relative h-8 w-full'>
            <img
              alt='BOHSA LTD'
              src='/logo-full-white.png'
              className={`absolute top-0 left-0 h-8 w-auto transition-opacity duration-300 ${
                isExpanded ? 'opacity-100' : 'opacity-0'
              }`}
            />
            <img
              alt='BOHSA LTD'
              src='/logo-star-white.png'
              className={`absolute top-0 left-0 right-0 mx-auto h-8 w-8 transition-opacity duration-300 ${
                isExpanded ? 'opacity-0' : 'opacity-100'
              }`}
            />
          </div>
        </NavLink>
      </div>
      <div className='flex-grow overflow-hidden'>
        <nav>
          <ul className='flex flex-col p-2'>{items.map(renderMenuItem)}</ul>
        </nav>
      </div>
      <div className='mt-auto flex flex-col p-2'>
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          className='flex items-center p-2 hover:bg-brand-800 rounded transition-colors duration-300 w-full cursor-pointer text-white'
        >
          <div className='w-6 h-6 flex items-center justify-center'>
            {isExpanded ? (
              <ChevronLeft size={20} className='flex-shrink-0 text-white' />
            ) : (
              <ChevronRight size={20} className='flex-shrink-0 text-white' />
            )}
          </div>
          {isExpanded && (
            <span className='ml-4 mr-2 whitespace-nowrap overflow-hidden transition-all duration-300 ease-in-out max-w-[200px]'>
              {isExpanded ? 'Collapse' : 'Expand'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
