import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import {
  useParams,
  useNavigate,
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import TaskItem from './TaskItem';
import TaskModel from '../../../models/Task';
import Pagination from '../../navigation/Pagination';
import { realtime, supabase } from '../../../utilities/supabase';
import TaskPopup from '../../popups/tasks/TaskPopup';
import UserProfile from '../../../models/UserProfile';
import { useContext } from 'react';
import { UserProfileContext } from '../../../App';

const TaskList = ({
  entityType,
  entityId,
  showCompletedTasks,
  showOnlyAssignedTasks
}) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedTask, setSelectedTask] = useState(null);
  const { '*': taskIdFromParams } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const userProfileContext = useContext(UserProfileContext);
  const currentUserId = userProfileContext.id;

  const tasksPerPage = 10;
  const defaults = [
    entityType,
    entityId,
    showCompletedTasks,
    showOnlyAssignedTasks
  ];

  const buildTaskHierarchy = flatTasks => {
    const taskMap = {};
    const rootTasks = [];

    flatTasks.forEach(task => {
      task.children = [];
      taskMap[task.id] = task;
    });

    flatTasks.forEach(task => {
      if (task.parent) {
        const parentTask = taskMap[task.parent];
        parentTask ? parentTask.children.push(task) : rootTasks.push(task);
      } else {
        rootTasks.push(task);
      }
    });

    return rootTasks;
  };

  const fetchTasks = async (loading = true) => {
    try {
      if (loading) setLoading(true);

      const filters = {
        entity_type: { value: entityType },
        entity_id: { value: entityId }
      };

      if (!showCompletedTasks) {
        filters.status = { operator: 'neq', value: '165' };
      }

      if (showOnlyAssignedTasks && currentUserId) {
        filters.assignees = { operator: 'cs', value: `{${currentUserId}}` };
      }

      const { data, total } = await TaskModel.getAll(
        filters,
        page,
        tasksPerPage,
        'order',
        'asc'
      );

      setTasks(buildTaskHierarchy(data));
      setTotalPages(total);

      if (taskIdFromParams) {
        const taskFromParams = data.find(
          task => task.id === parseInt(taskIdFromParams)
        );

        if (taskFromParams) {
          setSelectedTask(taskFromParams);
        }
      }
    } finally {
      if (loading) setLoading(false);
    }
  };

  useEffect(() => {
    const channel = realtime(
      '*',
      'tasks',
      () => fetchTasks(false),
      `entity_type=eq.${entityType}`,
      { entity_id: entityId }
    );

    return () => channel.unsubscribe();
  }, defaults);

  useEffect(() => {
    fetchTasks();
  }, [...defaults, page, taskIdFromParams, currentUserId]);

  useEffect(() => {
    if (taskIdFromParams) {
      const task = tasks.find(t => t.id === parseInt(taskIdFromParams));
      if (task) {
        setSelectedTask(task);
      }
    } else {
      setSelectedTask(null);
    }
  }, [taskIdFromParams, tasks]);

  const handleParentDrag = (newTasks, source, destination) => {
    const [reorderedTask] = newTasks.splice(source.index, 1);
    newTasks.splice(destination.index, 0, reorderedTask);
    return [destination.index + 1, null];
  };

  const handleChildDrag = (newTasks, source, destination) => {
    const findTaskById = (tasks, id) => {
      for (const task of tasks) {
        if (task.id === id) return task;
        if (task.children && task.children.length > 0) {
          const found = findTaskById(task.children, id);
          if (found) return found;
        }
      }
      return null;
    };

    const sourceParentId = parseInt(source.droppableId.split('-')[1]);
    const sourceParent = findTaskById(newTasks, sourceParentId);

    if (!sourceParent || !sourceParent.children) {
      console.error('Source parent not found or has no children');
      return [0, null];
    }

    const [reorderedTask] = sourceParent.children.splice(source.index, 1);

    if (source.droppableId !== destination.droppableId) {
      const destinationParentId = parseInt(
        destination.droppableId.split('-')[0]
      );
      const destinationParent = findTaskById(newTasks, destinationParentId);

      if (!destinationParent || !destinationParent.children) {
        console.error('Destination parent not found or has no children');
        sourceParent.children.splice(source.index, 0, reorderedTask); // Revert the change
        return [0, null];
      }

      destinationParent.children.splice(destination.index, 0, reorderedTask);
      return [destination.index + 1, destinationParentId];
    } else {
      sourceParent.children.splice(destination.index, 0, reorderedTask);
      return [destination.index + 1, sourceParentId];
    }
  };

  const onDragEnd = async ({ source, destination, draggableId, type }) => {
    if (
      !destination ||
      (source.droppableId === destination.droppableId &&
        source.index === destination.index)
    ) {
      return;
    }

    const newTasks = JSON.parse(JSON.stringify(tasks));
    let newOrder, parentId;

    if (type === 'parent') {
      [newOrder, parentId] = handleParentDrag(newTasks, source, destination);
    } else if (type === 'child') {
      [newOrder, parentId] = handleChildDrag(newTasks, source, destination);
    }

    setTasks(newTasks);

    new TaskModel({
      id: parseInt(draggableId),
      entity_type: entityType,
      entity_id: entityId,
      parent: parentId
    }).updateOrder(newOrder);
  };

  const handleTaskClick = task => {
    setSelectedTask(task);
    navigate(`${task.id}`);
  };

  const handleClosePopup = () => {
    setSelectedTask(null);
    navigate('.');
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='task' type='parent'>
          {provided => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className='divide-y divide-base-100 px-4 lg:px-0'
            >
              {tasks.map((task, index) => (
                <TaskItem
                  key={task.id}
                  task={task}
                  index={index}
                  onClick={handleTaskClick}
                />
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        onPageChange={setPage}
        loading={loading}
      />
      {selectedTask && (
        <TaskPopup
          entityType={entityType}
          task={selectedTask}
          onClose={handleClosePopup}
        />
      )}
    </>
  );
};

export default TaskList;
