import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItems, MenuItem, MenuButton } from '@headlessui/react';
import {
  ArrowLeft,
  ExternalLink,
  Edit,
  Mail,
  PhoneCall,
  Calendar,
  MoreHorizontal,
  Check
} from 'lucide-react';
import { TextButton, CircularIconButton, Badge } from 'core';
import Field from './Field';
import Accordion from './Accordion';
import CreateNote from '../popups/notes/CreateNote';
import SendEmailPopup from '../popups/email/SendEmailPopup';
import CoreEntity from '../../models/CoreEntity';

const EntityCard = ({
  entityId,
  entityType,
  infoItems,
  accordionItems,
  menuItems = [],
  data,
  onUpdate
}) => {
  const navigate = useNavigate();
  const [showActivityPopup, setShowActivityPopup] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [coreEntity, setCoreEntity] = useState({});

  useEffect(() => {
    const fetchEntityType = async () => {
      try {
        const coreEntity = await CoreEntity.getById(entityType);
        setCoreEntity(coreEntity);
      } catch (error) {
        console.error('Error fetching entity type:', error);
        setCoreEntity('Entity');
      }
    };

    fetchEntityType();
  }, [entityType]);

  const handleNoteClick = () => {
    setShowActivityPopup(true);
  };

  const handleActivityClose = () => {
    setShowActivityPopup(false);
  };

  const handleActivitySubmit = activityData => {
    // Handle the submitted activity data here
    console.log('Activity submitted:', activityData);
    // You might want to call an API or update the state here
  };

  const handleEmailClick = () => {
    setShowEmailPopup(true);
  };

  const handleEmailClose = () => {
    setShowEmailPopup(false);
  };

  const handleCopyId = () => {
    navigator.clipboard.writeText(entityId);
    setShowCopySuccess(true);
    setTimeout(() => {
      setShowCopySuccess(false);
    }, 1000);
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  return (
    <div className='bg-white dark:bg-base-900 border-r border-neutral-200 h-full overflow-y-auto flex flex-col w-full'>
      <div className='p-2 flex justify-between items-center mb-2'>
        <div className='flex items-center'>
          <TextButton
            onClick={() => {
              const path = `/${coreEntity.tableName.replace('_', '-')}`;
              navigate(path);
            }}
            className='mr-3'
            leftIcon={<ArrowLeft size={20} />}
            colour='base'
          />
          <h3 className='font-regular m-0'>{coreEntity.displayName}</h3>
          <div className='cursor-pointer ml-2'>
            <Badge
              colour={showCopySuccess ? 'success' : 'primary'}
              size='sm'
              rightIcon={showCopySuccess ? Check : null}
              onClick={handleCopyId}
            >
              {showCopySuccess ? 'Copied!' : `ID: ${entityId}`}
            </Badge>
          </div>
        </div>
        <div className='flex items-center'>
          <TextButton
            onClick={() => window.open(window.location.href, '_blank')}
            className='hover:text-brand-700'
            leftIcon={<ExternalLink size={20} />}
            colour='base'
          />
        </div>
      </div>
      <div className='px-4'>
        {infoItems.map((item, index) => (
          <Field
            key={index}
            Icon={item.icon}
            label={item.label}
            value={getNestedValue(data, item.displayField || item.field)}
            displayField={item.displayField || item.field}
            editField={item.editField || item.field}
            dataType={item.dataType}
            options={item.options}
            isEditable={item.isEditable}
            onUpdate={onUpdate}
            inlineLabel={item.inlineLabel}
            inlineEditor={item.inlineEditor}
          />
        ))}
        {/* QUICK ACTIONS */}
        <div className='mt-6 p-4 border border-neutral-100 rounded-lg'>
          <div className='flex justify-around'>
            <CircularIconButton
              Icon={Edit}
              label='Note'
              onClick={handleNoteClick}
            />
            <CircularIconButton
              Icon={Mail}
              label='Email'
              onClick={handleEmailClick}
            />
            <CircularIconButton
              Icon={PhoneCall}
              label='Call'
              onClick={() => {}}
            />
            <CircularIconButton
              Icon={Calendar}
              label='Task'
              onClick={() => {}}
            />
            <CircularIconButton
              Icon={Calendar}
              label='Meeting'
              onClick={() => {}}
            />
            <Menu as='div' className='relative inline-block text-left'>
              <MenuButton
                as={CircularIconButton}
                Icon={MoreHorizontal}
                label='More'
              />
              <MenuItems className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='px-1 py-1'>
                  {menuItems.length > 0 &&
                    menuItems.map((item, index) => (
                      <MenuItem key={index}>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? 'bg-primary-100 text-base-900'
                                : 'text-base-900'
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                            onClick={item.onClick}
                          >
                            {item.label}
                          </button>
                        )}
                      </MenuItem>
                    ))}
                </div>
              </MenuItems>
            </Menu>
          </div>
        </div>
      </div>
      <div className='mt-6'>
        <Accordion items={accordionItems} data={data} onUpdate={onUpdate} />
      </div>
      {showActivityPopup && (
        <CreateNote
          entityType={entityType}
          entityId={entityId}
          onClose={handleActivityClose}
          onSubmit={handleActivitySubmit}
        />
      )}
      {showEmailPopup && <SendEmailPopup onClose={handleEmailClose} />}
    </div>
  );
};

export default EntityCard;
