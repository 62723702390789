import { useState, useEffect } from 'react';
import { supabase } from '../../../utilities/supabase';
import Auth from '../Auth';
import { PrimaryInput } from '../../../components/inputs/Forms';
import { FilledButton } from 'core';
import { CheckCircle } from 'lucide-react';

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [formErrors, setFormErrors] = useState({});
  const [hasStarted, setHasStarted] = useState({
    email: false
  });

  useEffect(() => {
    let timer;
    if (emailSent && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prevCount => prevCount - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [emailSent, countdown]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setHasStarted(prev => ({ ...prev, [name]: true }));

    if (name === 'email') {
      const isValid = validateEmail(value);
      setFormErrors(prev => ({
        ...prev,
        email: isValid ? null : 'A valid email is required'
      }));
    }
  };

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleForgotPassword = async e => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setFormErrors({});

    const errors = {};
    if (!formData.email.trim()) errors.email = 'Email is required';
    else if (!validateEmail(formData.email))
      errors.email = 'A valid email is required';

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    try {
      const { error, data } = await supabase.auth.resetPasswordForEmail(
        formData.email,
        { redirectTo: 'http://localhost:5173/resetPassword' }
      );

      if (error) throw error;

      // Check if the email was actually sent
      if (data && data.user) {
        setEmailSent(true);
        setCountdown(60);
      } else {
        throw new Error('Email not sent. Please try again.');
      }
    } catch (error) {
      console.error('Password reset error:', error);
      setError(error.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Auth title='Forgot Password' onSubmit={handleForgotPassword} type='forgot'>
      {!emailSent ? (
        <>
          <PrimaryInput
            id='email'
            name='email'
            type='text'
            placeholder='Enter your email'
            value={formData.email}
            onChange={handleInputChange}
            label='Email'
            error={formErrors.email}
          />
          {error && <p className='text-red-500 text-sm'>{error}</p>}
          <FilledButton
            colour='primary'
            size='md'
            className='w-full'
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send Reset Email'}
          </FilledButton>
        </>
      ) : (
        <div className='text-center'>
          <CheckCircle className='w-16 h-16 text-[#ba1b1d] mx-auto mb-4' />
          <h3 className='text-xl font-semibold text-[#ba1b1d] mb-2'>
            Email Sent Successfully
          </h3>
          <p className='text-[#9c0709] mb-4'>
            We've sent a password reset email to your inbox. Please check your
            email and follow the instructions.
          </p>
          {countdown > 0 ? (
            <p className='text-neutral-600 mb-4'>
              You can request another email in {countdown} seconds.
            </p>
          ) : (
            <FilledButton
              colour='primary'
              size='md'
              className='w-full'
              onClick={() => setEmailSent(false)}
              disabled={countdown > 0}
            >
              Resend Email
            </FilledButton>
          )}
        </div>
      )}
    </Auth>
  );
};

export default ForgotPassword;
