import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Popup,
  FilledButton,
  SoftButton,
  ComboBox,
  StackedList,
  OutlineButton,
  TextButton
} from 'core';
import { supabase } from '../../../utilities/supabase';
import { X } from 'lucide-react';

const ManagePipelines = ({
  isOpen,
  onClose,
  pipelines,
  entityType,
  entityId,
  entity
}) => {
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [entityPipelines, setEntityPipelines] = useState([]);
  const navigate = useNavigate();

  const handleSave = async () => {
    try {
      const formattedPipelines = entityPipelines.map(pipeline => ({
        id: pipeline.id,
        group: 1,
        order: 0
      }));
      const updatedEntity = await entity.update({
        pipelines: formattedPipelines
      });
      setEntityPipelines(updatedEntity.pipelines);
      onClose();
    } catch (err) {
      console.error('Error saving pipelines:', err);
    }
  };

  const handleRemovePipeline = async pipelineId => {
    try {
      const updatedPipelines = entityPipelines.filter(
        pipeline => pipeline.id !== pipelineId
      );
      setEntityPipelines(updatedPipelines);
    } catch (err) {
      console.error('Error removing pipeline:', err);
    }
  };

  const handleViewPipeline = pipelineId => {
    navigate(`/pipelines/${pipelineId}`);
  };

  useEffect(() => {
    const fetchEntityPipelines = async () => {
      if (!pipelines || pipelines.length === 0) return;

      try {
        const { data, error } = await supabase
          .from('pipelines')
          .select('id, title')
          .in(
            'id',
            pipelines.map(p => p.id)
          );

        if (error) throw error;

        setEntityPipelines(data);
      } catch (error) {
        console.error('Error fetching entity pipelines:', error);
      }
    };

    fetchEntityPipelines();
  }, [pipelines]);

  const loadPipelineOptions = useCallback(async (query, page) => {
    try {
      const { data, error } = await supabase
        .from('pipelines')
        .select('id, title')
        .ilike('title', `%${query}%`)
        .range((page - 1) * 10, page * 10 - 1);

      if (error) throw error;
      return data.map(pipeline => ({
        value: pipeline.id,
        label: pipeline.title
      }));
    } catch (error) {
      console.error('Error loading pipeline options:', error);
      return [];
    }
  }, []);

  const handleAddPipeline = useCallback(
    selectedOption => {
      if (
        selectedOption &&
        !entityPipelines.some(p => p.id === selectedOption.value)
      ) {
        setEntityPipelines(prevPipelines => [
          ...prevPipelines,
          { id: selectedOption.value, title: selectedOption.label }
        ]);
        setSelectedPipeline(null);
      }
    },
    [entityPipelines]
  );

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Manage Pipelines'>
      <div className='p-4'>
        <ComboBox
          loadOptions={loadPipelineOptions}
          label='Add Pipeline'
          placeholder='Search for pipelines'
          onChange={handleAddPipeline}
          value={selectedPipeline}
          hint='Select a pipeline to add to this company'
        />
        <div className='mt-4 h-60 overflow-y-auto'>
          {entityPipelines.length > 0 ? (
            <StackedList
              items={entityPipelines.map(pipeline => ({
                title: pipeline.title,
                action: (
                  <>
                    <OutlineButton
                      size='sm'
                      colour='base'
                      className='mr-2'
                      onClick={() => handleViewPipeline(pipeline.id)}
                    >
                      View
                    </OutlineButton>
                    <TextButton
                      onClick={() => handleRemovePipeline(pipeline.id)}
                      colour='base'
                      size='sm'
                    >
                      <X size={18} />
                    </TextButton>
                  </>
                )
              }))}
            />
          ) : (
            <div className='flex items-center justify-center h-full'>
              <p className='text-gray-500 text-md italic'>
                This entity does not have any active pipelines.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className='flex justify-end p-4'>
        <SoftButton onClick={onClose} variant='secondary' className='mr-2'>
          Cancel
        </SoftButton>
        <FilledButton onClick={handleSave} variant='primary'>
          Save
        </FilledButton>
      </div>
    </Popup>
  );
};

export default ManagePipelines;
