import {
  Clock,
  Building,
  CheckCircle,
  PoundSterling,
  Users as UsersIcon,
  ReceiptText,
  Coins,
  Download,
  LoaderCircle,
  Globe,
  MessageSquare,
  Plus,
  Trash,
  Pencil
} from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import Company from '../../../models/Company';
import DateCard from '../../../components/entity/DateCard';
import {
  Badge,
  TextButton,
  SoftButton,
  Input,
  StackedList,
  Popup,
  FilledButton,
  DropdownInput
} from 'core';
import Field from '../../../components/entity/Field';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import UserProfile from '../../../models/UserProfile';
import Offices from './tabs/Offices';
import Bookings from './tabs/Bookings';
import Users from './tabs/Users';
import Tracker from './tabs/Tracker';
import AgreedRates from './tabs/AgreedRates';
import { supabase } from '../../../utilities/supabase';
import News from './tabs/News';
import {
  SiFacebook,
  SiGoogle,
  SiLinkedin,
  SiX
} from '@icons-pack/react-simple-icons';

const CompanyDetails = ({ currentUser }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [linkUrl, setLinkUrl] = useState('');
  const [termsPopupOpen, setTermsPopupOpen] = useState(false);
  const [termOptions, setTermOptions] = useState([]);
  const [terms, setTerms] = useState({ days: '', type: '' });
  const { id } = useParams();

  useEffect(() => {
    // Set the document title
    document.title = `Company #${id} | CRM | BOHSA LTD`;
    const fetchOptions = async () => {
      try {
        const options = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.CompanyStatus
        );
        const terms = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.CompanyTerms
        );
        const { data: fetchedUsers } = await UserProfile.getAll();

        const formattedOptions = options.map(option => ({
          label: option.type,
          value: option.id
        }));
        const formattedTerms = terms.map(option => ({
          label: option.type,
          value: option.id
        }));

        setStatusOptions(formattedOptions);
        setTermOptions(formattedTerms);
        setUsers(fetchedUsers);
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchOptions();
  }, []);

  const infoItems = [
    {
      icon: Building,
      label: 'Company',
      field: 'name',
      dataType: 'text',
      isEditable: true
    },
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.name',
      editField: 'status.id',
      dataType: 'select',
      isEditable: true,
      options: statusOptions
    },
    {
      icon: Building,
      label: 'Capsule ID',
      field: 'capsuleId',
      dataType: 'text',
      isEditable: true
    },
    {
      icon: Building,
      label: 'Account No#',
      field: 'accountNumber',
      dataType: 'text',
      isEditable: true
    },
    {
      icon: UsersIcon,
      label: 'Account Manager',
      displayField: 'accountManager',
      editField: 'accountManager',
      isEditable: true,
      dataType: 'user-select',
      options: users,
      inlineLabel: true,
      inlineEditor: false
    }
  ];

  const handleTermsEdit = () => {
    setTermsPopupOpen(true);
  };

  const determineType = url => {
    const domain = new URL(url).hostname.toLowerCase();
    if (domain.includes('facebook')) return 'Facebook';
    if (domain.includes('linkedin')) return 'LinkedIn';
    if (domain.includes('twitter') || domain.includes('x.com')) return 'X';
    if (domain.includes('google')) return 'Google';
    return 'Website';
  };

  const determineDisplay = url => {
    try {
      const parsedUrl = new URL(url);
      const hostname = parsedUrl.hostname.toLowerCase();
      const pathname = parsedUrl.pathname.toLowerCase();

      if (hostname.includes('twitter') || hostname.includes('x.com')) {
        const username = pathname.split('/').filter(Boolean)[0];
        return username ? `@${username}` : url;
      }

      if (hostname.includes('linkedin.com')) {
        const companyName = pathname.split('/').filter(Boolean)[1];
        return companyName ? companyName.replace(/-/g, ' ') : url;
      }

      if (hostname.includes('facebook.com')) {
        const pageName = pathname.split('/').filter(Boolean)[0];
        return pageName || hostname.replace('www.', '');
      }

      return parsedUrl.hostname;
    } catch (error) {
      console.error('Error parsing URL:', error);
      return url;
    }
  };

  const accordionItems = [
    {
      title: 'Links',
      content: ({ data, onUpdate }) => (
        <div>
          {data.links.map(link => (
            <div
              key={link.id}
              className='flex items-center justify-between mb-2 group'
            >
              <div className='flex items-center'>
                {link.type === 'Website' && (
                  <Globe className='mr-2' size={18} />
                )}
                {link.type === 'X' && <SiX className='mr-2 text-x' size={18} />}
                {link.type === 'LinkedIn' && (
                  <SiLinkedin className='mr-2 text-linkedin' size={18} />
                )}
                {link.type === 'Facebook' && (
                  <SiFacebook className='mr-2 text-facebook' size={18} />
                )}
                {link.type === 'Google' && (
                  <SiGoogle className='mr-2 text-google' size={18} />
                )}
                <a
                  href={link.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-primary-600 hover:text-primary-700 hover:underline transition-colors duration-200'
                >
                  {link.display}
                </a>
              </div>
              <TextButton
                size='sm'
                colour='danger'
                className='hidden group-hover:flex'
                onClick={() =>
                  onUpdate(
                    'links',
                    data.links.filter(l => l.id !== link.id)
                  )
                }
              >
                <Trash size={16} />
              </TextButton>
            </div>
          ))}
          <div className='flex items-center gap-x-1 mb-4'>
            <Input
              placeholder='Enter link URL'
              value={linkUrl}
              onChange={e => {
                const value = e.target.value.replace(/^https?:\/\//, '');
                setLinkUrl(value);
              }}
              leftAddon='https://'
            />
            <TextButton
              size='sm'
              colour='primary'
              className='w-32'
              leftIcon={<Plus />}
              onClick={() => {
                if (linkUrl) {
                  try {
                    const fullUrl = `https://${linkUrl}`;
                    const linkObject = {
                      id: Date.now(),
                      url: fullUrl,
                      display: determineDisplay(fullUrl),
                      type: determineType(fullUrl)
                    };
                    onUpdate('links', [...data.links, linkObject]);
                    setLinkUrl('');
                  } catch (error) {
                    console.error('Invalid URL:', error);
                    // Handle invalid URL (e.g., show an error message)
                  }
                }
              }}
            >
              Add link
            </TextButton>
          </div>
        </div>
      )
    },
    {
      title: 'Key Dates',
      content: ({ data }) => (
        <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
          <DateCard date={data.createdDate} label='Created' />
          <DateCard date={data.updatedDate} label='Updated' />
          <DateCard date={data.lastBooked} label='Last Booked' />
        </div>
      )
    },
    {
      title: 'Accounts',
      content: ({ data, onUpdate }) => (
        <div>
          <Field
            Icon={ReceiptText}
            label='On Account'
            value={data.onAccount ? 'Yes' : 'No'}
            onUpdate={onUpdate}
            editField='onAccount'
            dataType='select'
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
            isEditable={true}
          />
          <Field
            Icon={Clock}
            label='Terms'
            value={
              data.terms != null ? (
                <div className='flex items-center'>
                  <Badge colour='primary'>{data.terms.days}</Badge>
                  <span className='mx-2 whitespace-nowrap'>days after</span>
                  <Badge colour='primary'>{data.terms.type.type}</Badge>
                </div>
              ) : (
                'N/A'
              )
            }
            editField='terms'
            isEditable={false}
            onUpdate={onUpdate}
            extension={value => {
              return (
                <TextButton size='sm' onClick={handleTermsEdit}>
                  <Pencil size={16} />
                </TextButton>
              );
            }}
          />
          <Field
            Icon={Coins}
            label='Contract'
            value={data.creditContract ? data.creditContract.file_name : 'No'}
            onUpdate={onUpdate}
            isEditable={false}
            extension={value => {
              const [isDownloading, setIsDownloading] = useState(false);

              return (
                <TextButton
                  onClick={async () => {
                    setIsDownloading(true);
                    try {
                      const { data: response, error } =
                        await supabase.functions.invoke(
                          'storage/getSigned/download',
                          {
                            body: {
                              key: `credit_contracts/${
                                data.creditContract.id
                              }.${data.creditContract.file_name
                                .split('.')
                                .pop()}`,
                              downloadAs: data.creditContract.file_name
                            }
                          }
                        );

                      if (error) throw error;

                      const link = document.createElement('a');
                      link.href = response.url;
                      link.download = data.creditContract.file_name;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    } catch (error) {
                      console.error('Error downloading document:', error);
                      // Handle error (e.g., show an error message to the user)
                    } finally {
                      setIsDownloading(false);
                    }
                  }}
                  disabled={isDownloading}
                >
                  {isDownloading ? (
                    <LoaderCircle size={16} className='animate-spin' />
                  ) : (
                    <Download size={16} />
                  )}
                </TextButton>
              );
            }}
          />
          <Field
            Icon={Coins}
            label='Tradex'
            value={data.tradex ? 'Yes' : 'No'}
            onUpdate={onUpdate}
            editField='tradex'
            dataType='select'
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
            isEditable={true}
          />
          {data.tradex && (
            <Field
              Icon={Coins}
              label='Tradex Options'
              editField='tradexOption'
              value={data.tradexOption}
              onUpdate={onUpdate}
              dataType='select'
              options={[
                { label: '0', value: 0 },
                { label: '24', value: 24 }
              ]}
              isEditable={true}
            />
          )}

          <Field
            Icon={PoundSterling}
            label='Company Levy'
            value={data.levy ?? 'N/A'}
            editField='levy'
            isEditable={true}
            onUpdate={onUpdate}
            dataType='text'
          />
          <Field
            Icon={MessageSquare}
            label='Account Comment'
            value={data.accountComment}
            editField='accountComment'
            isEditable={true}
            onUpdate={onUpdate}
            dataType='text'
          />
        </div>
      )
    }
  ];

  const additionalTabs = [
    { label: 'News', path: 'news', content: <News companyId={id} /> },
    { label: 'Offices', path: 'offices', content: <Offices companyId={id} /> },
    {
      label: 'Bookings',
      path: 'bookings',
      content: <Bookings companyId={id} />
    },
    { label: 'Users', path: 'users', content: <Users companyId={id} /> },
    { label: 'Tracker', path: 'tracker', content: <Tracker companyId={id} /> },
    {
      label: 'Agreed Rates',
      path: 'rates',
      content: <AgreedRates companyId={id} />
    },
    { label: 'Levy Tracker', path: 'levies', content: <div>Test content</div> }
  ];

  return (
    <>
      <Entity
        currentUser={currentUser}
        entityType={EntityTypeEnum.Company}
        model={Company}
        infoItems={infoItems}
        accordionItems={accordionItems}
        additionalTabs={additionalTabs}
      />
      <Popup
        isOpen={termsPopupOpen}
        onClose={() => setTermsPopupOpen(false)}
        title='Edit Terms'
        size='sm'
      >
        <div className='space-y-2'>
          <Input
            type='number'
            placeholder='Days'
            value={terms.days}
            onChange={e => setTerms({ ...terms, days: e.target.value })}
          />
          <DropdownInput
            options={termOptions}
            value={terms.type}
            onChange={e => setTerms({ ...terms, type: e.target.value })}
            placeholder='Select Type'
            label='Type'
          />
          <div className='flex justify-end'>
            <FilledButton
              size='md'
              colour='primary'
              onClick={async () => {
                const company = await Company.getById(id);
                company.terms = {
                  days: terms.days,
                  type: { id: terms.type }
                };
                await company.update();
                setTermsPopupOpen(false);
              }}
            >
              Save
            </FilledButton>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default CompanyDetails;
