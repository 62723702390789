import { EntityTypeEnum } from './Enumerables';

// Permission mapping
export const PERMISSIONS = {
  [EntityTypeEnum.Booking]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Quote]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8
  },
  [EntityTypeEnum.Pipelines]: {
    VIEW: 1,
    EDIT: 2,
    CREATE: 4,
    DELETE: 8
  }
};
