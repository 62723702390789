import React, { useState, useEffect } from 'react';
import { Popup, Input, FilledButton } from 'core';
import Booking from '../../../models/Booking';

const CreateInvoice = ({ isOpen, onClose, bookingId }) => {
  const [booking, setBooking] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState({
    description: '',
    amount: '',
    dueDate: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const fetchedBooking = await Booking.getById(bookingId);
        if (!fetchedBooking) {
          throw new Error('Booking not found');
        }
        setBooking(fetchedBooking);
        setInvoiceDetails(prev => ({
          ...prev,
          description: fetchedBooking.course?.name || '',
          amount: fetchedBooking.invoiceAmount || '',
          dueDate: fetchedBooking.paymentDueDate || ''
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load booking details. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [bookingId, isOpen]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setInvoiceDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Handle invoice creation logic here
    console.log('Creating invoice:', invoiceDetails);
  };

  if (isLoading) {
    return (
      <Popup title='Loading...' isOpen={isOpen} onClose={onClose} size='lg'>
        <p>Loading booking details...</p>
      </Popup>
    );
  }

  if (error) {
    return (
      <Popup title='Error' isOpen={isOpen} onClose={onClose} size='lg'>
        <p>{error}</p>
      </Popup>
    );
  }

  if (!booking) {
    return (
      <Popup title='Error' isOpen={isOpen} onClose={onClose} size='lg'>
        <p>No booking data available.</p>
      </Popup>
    );
  }

  return (
    <Popup title='Create Invoice' isOpen={isOpen} onClose={onClose} size='lg'>
      <div className='flex'>
        <div className='w-1/2 pr-4'>
          {/*container for spaces warning */}

          {booking.spaces > booking.metrics.fulfilledSpaces &&
          booking.deliveryType.id === 62 ? (
            <div className='bg-base-100 rounded-md px-4 py-2 text-sm mb-2'>
              <p>
                You are about to create an invoice with some delegate spots
                unfilled. Since this is an <strong>Onsite</strong> booking, it's
                common for all spots not to be occupied.
              </p>
            </div>
          ) : (
            <div className='bg-warning-100 rounded-md px-4 py-2 text-sm mb-2'>
              <p className='text-warning-700'>
                You are about to create an invoice with some delegate spots
                unfilled. Since this is an{' '}
                <strong>{booking.deliveryType.name}</strong> booking, it is
                important that all booking spaces are filled. Please consult
                your manager for assistance.
              </p>
            </div>
          )}
          {booking.spaces < booking.metrics.fulfilledSpaces && (
            <div className='bg-danger-100 rounded-md px-4 py-2 text-sm mb-2'>
              <p className='text-danger-700'>
                You are about to create an invoice for a booking which has{' '}
                <strong>too many spaces</strong>. It is strongly recommended
                that you correct this before completing the invoice.
              </p>
            </div>
          )}

          {/*form inputs */}
          <form onSubmit={handleSubmit} className='space-y-4'>
            <Input
              label='Description'
              name='description'
              value={invoiceDetails.description}
              onChange={handleInputChange}
            />
            <Input
              label='Amount'
              name='amount'
              type='number'
              value={invoiceDetails.amount}
              onChange={handleInputChange}
            />
            <Input
              label='Due Date'
              name='dueDate'
              type='date'
              value={invoiceDetails.dueDate}
              onChange={handleInputChange}
            />
            <FilledButton type='submit' colour='primary'>
              Create Invoice
            </FilledButton>
          </form>
          {/*container for create - warning */}
        </div>
        <div className='w-1/2 pl-4 border-l'>
          <h5 className='text-sm font-semibold mb-2'>Invoice Preview</h5>
          <div className='p-4 rounded border min-h-[550px] flex flex-col'>
            <h5 className='text-lg font-semibold'>Invoice</h5>
            <div className='flex justify-between mb-4'>
              <div>
                <p className='text-sm'>
                  {booking.company?.name || 'Company Name'}
                </p>
              </div>
              <div>
                <p className='text-sm'>
                  <strong>Issue Date:</strong> {new Date().toLocaleDateString()}
                </p>
                <p className='text-sm'>
                  <strong>Due Date:</strong>{' '}
                  {invoiceDetails.dueDate
                    ? new Date(invoiceDetails.dueDate).toLocaleDateString()
                    : 'Not set'}
                </p>
              </div>
            </div>
            <div className='flex-grow overflow-auto'>
              <table className='min-w-full'>
                <thead className='bg-base-100 text-xs font-semibold'>
                  <tr>
                    <th className='text-left pe-2'>Code</th>
                    <th className='text-left pe-2'>QTY</th>
                    <th className='text-left pe-2'>Description</th>
                    <th className='text-left pe-2'>Rate</th>
                    <th className='text-left pe-2'>VAT</th>
                  </tr>
                </thead>
                <tbody className='text-xs'>
                  <tr>
                    <td className='pe-2'>SB - {booking.id}</td>
                    <td className='pe-2'>1</td>
                    <td className='pe-2'>{invoiceDetails.description}</td>
                    <td className='pe-2'>£{invoiceDetails.amount}</td>
                    <td className='pe-2'>£0.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='mt-4 text-xs flex flex-col items-end'>
              <p>
                <strong>Sub Total:</strong> £
                {(parseFloat(invoiceDetails.amount) || 0).toFixed(2)}
              </p>
              <p>
                <strong>VAT Total:</strong> £0.00
              </p>
              <p>
                <strong>Total:</strong> £
                {(parseFloat(invoiceDetails.amount) || 0).toFixed(2)}
              </p>
            </div>
            <div className='mt-4'>
              <img
                src='/iris-payment-logo.svg'
                alt='IRIS Pay Logo'
                className='h-6 w-auto'
              />
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default CreateInvoice;
