import BaseModel from './BaseModel';

/**
 * Represents a Location in the system.
 * @extends BaseModel
 */
class Location extends BaseModel {
  /** @type {string} The name of the database table for Locations */
  static table = 'locations';

  /** @type {string} The SQL query to select Location data */
  static selectQuery =
    '*,location_type:core_entity_types!location_type(id, type),status:core_entity_types!status(id,type)';

  /**
   * Creates an instance of Location.
   * @param {Object} data - The location data.
   */
  constructor(data = {}) {
    super(data);
    this.name = data.name || null;
    this.address1 = data.address_1 || null;
    this.address2 = data.address_2 || null;
    this.city = data.city || null;
    this.county = data.county || null;
    this.postcode = data.postcode || null;
    // Create a full address property
    this.fullAddress = [
      this.address1,
      this.address2,
      this.city,
      this.county,
      this.postcode
    ]
      .filter(Boolean)
      .join(', ');
    this.locationType = data.location_type || null;
    this.entityType = data.entity_type || null;
    this.entityId = data.entity_id || null;
    this.status = data.status || null;
    this.metadata = data.metadata || {};
  }

  /**
   * Converts the Location instance to a database-friendly format.
   * @returns {Object} The location data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      address_1: this.address1,
      address_2: this.address2,
      city: this.city,
      county: this.county,
      postcode: this.postcode,
      location_type: this.locationType,
      entity_type: this.entityType,
      entity_id: this.entityId,
      status: this.status,
      metadata: this.metadata
    };
  }

  /**
   * Search for locations based on a query string.
   * @param {string} query - The search query.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @returns {Promise<Object>} A promise that resolves to an object containing the search results and pagination info.
   */
  static async search(query, page = 1, size = 10) {
    try {
      let dbQuery = this.filters({
        or: `name.ilike.%${query}%,address_1.ilike.%${query}%,address_2.ilike.%${query}%,city.ilike.%${query}%,postcode.ilike.%${query}%`
      });

      return this.getAll(dbQuery, page, size, 'name', 'asc');
    } catch (error) {
      console.error('[Error] Searching Locations: ', error);
      throw error;
    }
  }
}

export default Location;
