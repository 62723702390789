import React from 'react';

/**
 * Badge Component
 *
 * @param {string} [props.colour='primary'] - The colour of the badge ('brand', 'primary', 'info', 'success', 'warning', 'danger', or a custom Tailwind color)
 * @param {string} [props.size='md'] - The size of the badge ('xs', 'sm', 'md', 'lg', 'xl')
 * @param {React.ReactNode} props.children - The content to be rendered inside the badge
 * @param {React.ReactElement} [props.leftIcon] - The icon to be rendered on the left side of the badge
 * @param {React.ReactElement} [props.rightIcon] - The icon to be rendered on the right side of the badge
 * @param {function} [props.onRemove] - Callback function when remove button is clicked
 * @param {string} [props.shape='badge'] - The shape of the component ('badge', 'pill')
 * @param {Object} props - The properties passed to the component
 * @returns {React.ReactElement} A badge element with the specified properties
 */
const Badge = ({
  shape = 'badge',
  colour = 'primary',
  size = 'md',
  leftIcon: LeftIcon,
  children,
  rightIcon: RightIcon,
  onRemove,
  ...props
}) => {
  const sizeClasses = {
    xs: 'px-1 py-0.5 text-xs',
    sm: 'px-2 py-1 text-xs',
    md: 'px-2 py-1 text-xs',
    lg: 'px-2 py-1.5 text-sm',
    xl: 'px-3 py-2 text-sm'
  };

  const iconClasses = {
    xs: 'h-3 w-3',
    sm: 'h-3 w-3',
    md: 'h-3.5 w-3.5',
    lg: 'h-4 w-4',
    xl: 'h-5 w-5'
  };

  const colourClasses = {
    brand: 'bg-brand-50 text-brand-700 ring-brand-600/20',
    primary: 'bg-primary-50 text-primary-700 ring-primary-600/20',
    info: 'bg-info-50 text-info-700 ring-info-600/20',
    success: 'bg-success-50 text-success-700 ring-success-600/20',
    warning: 'bg-warning-50 text-warning-700 ring-warning-600/20',
    danger: 'bg-danger-50 text-danger-700 ring-danger-600/20'
  };

  const shapeClasses = {
    badge: 'rounded-md',
    pill: 'rounded-full'
  };

  const removeButtonClasses = {
    brand: {
      badge:
        'hover:bg-brand-600/20 stroke-brand-600/50 group-hover:stroke-brand-600/75 rounded-sm',
      pill: 'hover:bg-brand-600/20 stroke-brand-600/50 group-hover:stroke-brand-600/75 rounded-full'
    },
    primary: {
      badge:
        'hover:bg-primary-600/20 stroke-primary-600/50 group-hover:stroke-primary-600/75 rounded-sm',
      pill: 'hover:bg-primary-600/20 stroke-primary-600/50 group-hover:stroke-primary-600/75 rounded-full'
    },
    info: {
      badge:
        'hover:bg-info-600/20 stroke-info-600/50 group-hover:stroke-info-600/75 rounded-sm',
      pill: 'hover:bg-info-600/20 stroke-info-600/50 group-hover:stroke-info-600/75 rounded-full'
    },
    success: {
      badge:
        'hover:bg-success-600/20 stroke-success-600/50 group-hover:stroke-success-600/75 rounded-sm',
      pill: 'hover:bg-success-600/20 stroke-success-600/50 group-hover:stroke-success-600/75 rounded-full'
    },
    warning: {
      badge:
        'hover:bg-warning-600/20 stroke-warning-600/50 group-hover:stroke-warning-600/75 rounded-sm',
      pill: 'hover:bg-warning-600/20 stroke-warning-600/50 group-hover:stroke-warning-600/75 rounded-full'
    },
    danger: {
      badge:
        'hover:bg-danger-600/20 stroke-danger-600/50 group-hover:stroke-danger-600/75 rounded-sm',
      pill: 'hover:bg-danger-600/20 stroke-danger-600/50 group-hover:stroke-danger-600/75 rounded-full'
    }
  };

  const getColourClasses = colour => {
    if (colourClasses[colour]) {
      return colourClasses[colour];
    }
    // For custom Tailwind colors
    return `bg-${colour}-50 text-${colour}-700 ring-${colour}-600/20`;
  };

  const getRemoveButtonClasses = (colour, shape) => {
    if (removeButtonClasses[colour]) {
      return removeButtonClasses[colour][shape];
    }
    // For custom Tailwind colors
    return `hover:bg-${colour}-600/20 stroke-${colour}-600/50 group-hover:stroke-${colour}-600/75 rounded-${
      shape === 'badge' ? 'sm' : 'full'
    }`;
  };

  return (
    <span
      className={`inline-flex items-center gap-x-0.5 ${
        sizeClasses[size]
      } ${getColourClasses(colour)} ${shapeClasses[shape]} ring-1 ring-inset whitespace-nowrap font-medium`}
      {...props}
    >
      {LeftIcon && <LeftIcon className={`${iconClasses[size]} mr-1`} />}
      {children}
      {RightIcon && <RightIcon className={`${iconClasses[size]} ml-1`} />}
      {onRemove && (
        <button
          type='button'
          className={`group relative -mr-1 h-3.5 w-3.5 ${getRemoveButtonClasses(
            colour,
            shape
          )}`}
          onClick={onRemove}
        >
          <span className='sr-only'>Remove</span>
          <svg viewBox='0 0 14 14' className='h-3.5 w-3.5'>
            <path d='M4 4l6 6m0-6l-6 6' />
          </svg>
          <span className='absolute -inset-1' />
        </button>
      )}
    </span>
  );
};

export { Badge };
