import React from 'react';
import { Badge, TextButton } from 'core';

const ActiveFilters = ({ filters, removeFilter, removeAllFilters }) => {
  if (Object.keys(filters).length === 0) {
    return null;
  }

  return (
    <div className='flex flex-wrap gap-2 mt-2 bg-base-50 items-center py-3 px-4 rounded-md'>
      <p className='text-base-500 text-sm leading-6 font-medium'>Filters</p>
      <div className='mx-2 h-5 w-px bg-base-300' />
      {Object.entries(filters).map(
        ([key, value]) =>
          value && (
            <Badge
              key={key}
              colour='primary'
              shape='pill'
              onRemove={() => removeFilter(key)}
            >
              {`${value.label}: ${value.value}`}
            </Badge>
          )
      )}
      <TextButton onClick={() => removeAllFilters()} colour='primary'>
        Clear all
      </TextButton>
    </div>
  );
};

export default ActiveFilters;
