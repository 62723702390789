import React from 'react';
import UserProfile from '../../../models/UserProfile';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Link, useNavigate } from 'react-router-dom';
import { Pencil, Trash, SquareArrowOutUpRight } from 'lucide-react';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const Users = () => {
  //Set the page title
  document.title = 'Users | CRM | BOHSA LTD';
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/users/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/users/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleRowClick = data => {
    navigate(`/users/${data.id}`);
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Users'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          { field: 'first_name', label: 'First Name', type: 'text' },
          { field: 'last_name', label: 'Last Name', type: 'text' },
          { field: 'email', label: 'Email', type: 'text' },
          { field: 'created_date_from', label: 'Created From', type: 'date' },
          { field: 'created_date_to', label: 'Created To', type: 'date' }
        ]}
        sort={[
          { value: 'first_name', label: 'Name' },
          { value: 'created_date', label: 'Date Created' },
          { value: 'updated_date', label: 'Last Modified' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/users/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'First Name', field: 'firstName' },
          { label: 'Last Name', field: 'lastName' },
          { label: 'Email', field: 'email' },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={UserProfile}
        entityType={EntityTypeEnum.User}
        onRowClick={handleRowClick}
        canCreate={true}
      />
    </div>
  );
};

export default Users;
