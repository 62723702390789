import React, { useState, useEffect } from 'react';
import { supabase } from '../../utilities/supabase';
import UserProfile from '../../models/UserProfile';
import Header from '../../components/navigation/Header';
import { UserRound, Camera, Save, Trash, SquareKanban, ListTodo, FileSpreadsheet, CalendarCheck, TicketCheck, UsersIcon, Building, GraduationCap, Presentation, ReceiptText, Folders } from 'lucide-react';
import {
  LogoLoader,
  FilledButton,
  OutlineButton,
  TextButton,
  NotificationItem
} from 'core';
import { PrimaryInput, DropdownInput } from '../../components/inputs/Forms';

const Account = () => {
  const [profile, setProfile] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phones, setPhones] = useState([]);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [notificationConfig, setNotificationConfig] = useState({});

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const userProfile = await UserProfile.getByAuthId(user.id);
        setProfile(userProfile);
        setFirstName(userProfile.firstName || '');
        setLastName(userProfile.lastName || '');
        setEmail(userProfile.email || '');
        setPhones(userProfile.phones || []);
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setNotificationConfig({
        type: 'error',
        title: 'Error',
        description: 'Passwords do not match'
      });
      setShowNotification(true);
      return;
    }

    try {
      const updatedData = {
        first_name: firstName,
        last_name: lastName,
        phones: phones
      };

      await profile.update(updatedData);

      if (password) {
        const { error } = await supabase.auth.updateUser({ password });
        if (error) throw error;
      }

      setNotificationConfig({
        type: 'success',
        title: 'Profile updated',
        description: 'Your profile changes have been saved successfully.'
      });
      setShowNotification(true);
    } catch (error) {
      console.error('Error updating profile:', error);
      setNotificationConfig({
        type: 'error',
        title: 'Error',
        description: 'Error updating profile'
      });
      setShowNotification(true);
    }
  };

  const handlePhoneChange = (index, field, value) => {
    const updatedPhones = [...phones];
    updatedPhones[index] = { ...updatedPhones[index], [field]: value };
    setPhones(updatedPhones);
  };

  const addPhone = () => {
    setPhones([...phones, { type: '', number: '', country_code: '' }]);
  };

  const removePhone = (index) => {
    setPhones(phones.filter((_, i) => i !== index));
  };

  if (!profile) {
    return <LogoLoader logo={'/logo-star.png'} />;
  }

  const phoneTypeOptions = [
    { value: 'mobile', label: 'Mobile' },
    { value: 'home', label: 'Home' },
    { value: 'work', label: 'Work' },
    { value: 'other', label: 'Other' }
  ];

  const sidebarItems = [
    { icon: SquareKanban, name: 'Pipelines', path: '/pipelines' },
    { icon: ListTodo, name: 'Tasks', path: '/tasks' },
    { icon: FileSpreadsheet, name: 'Quotes', path: '/quotes' },
    { icon: CalendarCheck, name: 'Bookings', path: '/bookings' },
    { icon: TicketCheck, name: 'Booking Spaces', path: '/booking-spaces' },
    { icon: UsersIcon, name: 'Users', path: '/user-profiles' },
    { icon: Building, name: 'Companies', path: '/companies' },
    { icon: GraduationCap, name: 'Courses', path: '/courses' },
    { icon: Presentation, name: 'Trainers', path: '/trainers' },
    { icon: ReceiptText, name: 'Invoices', path: '/invoices' },
    { icon: Folders, name: 'Documents', path: '/documents' }
  ];

  return (
    <div className='min-h-screen bg-neutral-50'>
      <Header sidebarItems={sidebarItems} />
      <div className='max-w-6xl mx-auto px-4 sm:px-6 lg:px-2 py-10 space-y-20'>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
            <div className='md:col-span-1'>
              <h2 className='text-lg font-medium text-neutral-600'>Profile</h2>
              <p className='mt-1 text-sm text-neutral-400'>
                This information will be displayed in various places within the
                CRM and will be visible by other users.
              </p>
            </div>
            <div className='md:col-span-2 bg-white shadow-md rounded-lg overflow-hidden'>
              <div className='p-6 space-y-6'>
                <div className='flex items-center space-x-6'>
                  <div className='shrink-0'>
                    {profile.profilePhoto ? (
                      <img
                        src={profile.profilePhoto}
                        alt='User'
                        className='h-16 w-16 rounded-full object-cover border-2 border-neutral-200'
                      />
                    ) : (
                      <UserRound className='h-14 w-14 text-white bg-neutral-100 rounded-full p-3' />
                    )}
                  </div>
                  <OutlineButton colour='base' leftIcon={<Camera />}>
                    Change
                  </OutlineButton>
                </div>
                <div className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6'>
                  <PrimaryInput
                    id='firstName'
                    name='firstName'
                    label='First name'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <PrimaryInput
                    id='lastName'
                    name='lastName'
                    label='Last name'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mt-6'>
            <div className='md:col-span-1'>
              <h2 className='text-lg font-medium text-neutral-600'>Security</h2>
              <p className='mt-1 text-sm text-neutral-400'>
                Change your password.
              </p>
            </div>
            <div className='md:col-span-2 bg-white shadow-md rounded-lg overflow-hidden'>
              <div className='p-6 space-y-6'>
                <div className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6'>
                  <PrimaryInput
                    id='password'
                    name='password'
                    type='password'
                    label='New Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <PrimaryInput
                    id='confirmPassword'
                    name='confirmPassword'
                    type='password'
                    label='Confirm New Password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mt-6'>
            <div className='md:col-span-1'>
              <h2 className='text-lg font-medium text-neutral-600'>
                Contact Details
              </h2>
              <p className='mt-1 text-sm text-neutral-400'>
                Manage your contact details.
              </p>
            </div>
            <div className='md:col-span-2 bg-white shadow-md rounded-lg overflow-hidden'>
              <div className='p-6 space-y-6'>
                <div className='sm:col-span-2'>
                  <h3 className='text-md font-medium text-gray-900 mb-2'>
                    Phone Numbers
                  </h3>
                  {phones.map((phone, index) => (
                    <div key={index} className='flex items-center space-x-4 mb-4'>
                      <DropdownInput
                        id={`phoneType-${index}`}
                        name={`phoneType-${index}`}
                        value={phone.type}
                        onChange={(e) =>
                          handlePhoneChange(index, 'type', e.target.value)
                        }
                        options={phoneTypeOptions}
                        className={'min-w-24'}
                      />
                      <PrimaryInput
                        id={`phoneNumber-${index}`}
                        name={`phoneNumber-${index}`}
                        value={phone.number}
                        onChange={(e) =>
                          handlePhoneChange(index, 'number', e.target.value)
                        }
                      />
                      <TextButton
                        colour='danger'
                        size='lg'
                        onClick={() => removePhone(index)}
                      >
                        <Trash />
                      </TextButton>
                    </div>
                  ))}
                  <OutlineButton colour='base' size='md' onClick={addPhone}>
                    Add Phone
                  </OutlineButton>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-6 text-right'>
            <FilledButton type='submit' leftIcon={<Save />}>
              Save All Changes
            </FilledButton>
          </div>
        </form>
      </div>
      {showNotification && (
        <NotificationItem
          type={notificationConfig.type}
          title={notificationConfig.title}
          description={notificationConfig.description}
          onClose={() => setShowNotification(false)}
        />
      )}
    </div>
  );
};

export default Account;
