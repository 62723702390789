import React, { useState, useEffect, useRef } from 'react';
import { Search, X } from 'lucide-react';
import { debounce } from 'lodash';
import { Input } from 'core';
import { supabase } from '../../utilities/supabase';
import Table from '../tables/Table';
import { TextButton, Badge } from 'core';
import { Link } from 'react-router-dom';

const GlobalSearch = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sideNavWidth, setSideNavWidth] = useState('56px');
  const searchRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.ctrlKey && e.key === 'k') {
        e.preventDefault();
        if (searchRef.current) {
          searchRef.current.querySelector('input').focus();
        }
      } else if (e.key === 'Escape') {
        setSearchQuery('');
        if (searchRef.current) {
          searchRef.current.querySelector('input').blur();
        }
        setIsSearchOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    const updateSideNavWidth = () => {
      const sideNav = document.getElementById('sideNav');
      if (sideNav) {
        setSideNavWidth(`${sideNav.offsetWidth}px`);
      }
    };

    updateSideNavWidth();
    window.addEventListener('resize', updateSideNavWidth);

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target.id === 'sideNav') {
          setSideNavWidth(`${entry.contentRect.width}px`);
        }
      }
    });

    const sideNav = document.getElementById('sideNav');
    if (sideNav) {
      resizeObserver.observe(sideNav);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', updateSideNavWidth);
      if (sideNav) {
        resizeObserver.unobserve(sideNav);
      }
    };
  }, []);

  useEffect(() => {
    if (isSearchOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSearchOpen]);

  const performSearch = async query => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }
    setIsLoading(true);
    try {
      const { data, error } = await supabase.rpc('global_search', {
        search_query: query
      });
      if (error) throw error;
      setSearchResults(data);
    } catch (error) {
      console.error('Error performing search: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = debounce(performSearch, 300);

  const handleSearchChange = e => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const getTableHeaders = results => {
    const headers = {};
    results.forEach(result => {
      if (!headers[result.table_name]) {
        headers[result.table_name] = new Set(Object.keys(result.data));
      } else {
        Object.keys(result.data).forEach(key =>
          headers[result.table_name].add(key)
        );
      }
    });
    return headers;
  };

  const createColumns = (path, headers) => {
    return Array.from(headers).map(header => ({
      label:
        header.charAt(0).toUpperCase() + header.slice(1).replace(/_/g, ' '),
      field: header,
      cell: value => {
        if (header.includes('date') && value) {
          return new Date(value).toLocaleDateString();
        }
        if (header == 'id' && value) {
          return (
            <Link
              to={`/${path}/${value}`}
              className='text-brand-700 hover:text-brand-800'
            >
              {value}
            </Link>
          );
        }
        return value || 'N/A';
      }
    }));
  };

  return (
    <div className='relative' ref={searchRef}>
      {/* For larger screens */}
      <div className='hidden sm:block'>
        <Input
          id='globalsearch'
          name='globalsearch'
          type='text'
          placeholder='Search...'
          className='bg-white text-gray-500'
          value={searchQuery}
          onChange={handleSearchChange}
          onFocus={() => setIsSearchOpen(true)}
          leftIcon={Search}
          rightIcon={() => (
            <div className='flex items-center gap-1'>
              <Badge colour='primary' size='xs'>
                Ctrl
              </Badge>
              <Badge colour='primary' size='xs'>
                K
              </Badge>
            </div>
          )}
        />
      </div>

      {/* For smaller screens */}
      <div className='sm:hidden'>
        <button
          onClick={() => setIsSearchOpen(true)}
          className='px-2 rounded-md bg-brand-700 text-white flex items-center'
        >
          <Search size={24} className='mr-1' />
          {/* <span className='text-sm'>Search...</span> */}
        </button>
      </div>

      {isSearchOpen && (
        <div
          className={`fixed inset-0 bg-white z-50 overflow-hidden top-[48px] lg:left-[${sideNavWidth}]`}
          style={{ left: sideNavWidth }}
        >
          <div className='p-4 flex flex-col h-full'>
            <div className='flex justify-between items-center mb-4'>
              <h4 className='text-xl font-semibold text-gray-700'>
                Global Search
              </h4>
              <TextButton
                colour='base'
                size='md'
                onClick={() => setIsSearchOpen(false)}
                rightIcon={<X size={24} />}
              />
            </div>
            <div className='sm:hidden'>
              <Input
                id='globalsearch'
                name='globalsearch'
                type='text'
                placeholder='Search...'
                className='bg-white text-gray-500'
                value={searchQuery}
                onChange={handleSearchChange}
                onFocus={() => setIsSearchOpen(true)}
                leftIcon={Search}
              />
            </div>

            {searchResults.length > 0 && (
              <div id='search-results' className='overflow-y-auto flex-grow'>
                {(() => {
                  const groupedResults = searchResults.reduce((acc, result) => {
                    const tableName = result.table_name;
                    if (!acc[tableName]) acc[tableName] = [];
                    acc[tableName].push(result);
                    return acc;
                  }, {});

                  const tableHeaders = getTableHeaders(searchResults);

                  return Object.entries(groupedResults).map(
                    ([tableName, results]) => (
                      <div
                        key={tableName}
                        className='p-4 border-b border-gray-200'
                      >
                        <h3 className='text-md font-regular mb-2'>
                          {tableName}
                        </h3>
                        <div className='overflow-x-auto'>
                          <Table
                            columns={createColumns(
                              results[0].path,
                              tableHeaders[tableName]
                            )}
                            data={results.map(result => result.data)}
                            isLoading={isLoading}
                            isError={false}
                            rowClassName='hover:bg-gray-100'
                          />
                        </div>
                      </div>
                    )
                  );
                })()}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalSearch;
